import { businessModels } from '@/lib/constants';
import { Select } from 'antd';
import { FC } from 'react';

type Props = {
	placeholder: string;
	onChange?: (value: string) => void;
};

const BusinessModelSelect: FC<Props> = (props: Props) => {
	return (
		<Select
			{...props}
			showSearch
			mode="multiple"
			optionFilterProp="children"
			filterOption={(input, option) =>
				(option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
			}>
			{businessModels.map((model) => (
				<Select.Option key={model} value={model}>
					{model}
				</Select.Option>
			))}
		</Select>
	);
};

export default BusinessModelSelect;
