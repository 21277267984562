import { CopyOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, message, Space } from 'antd';
import { ReactComponent as Logo } from '../assets/logo-light.svg';

type Props = {
	error: Error;
	componentStack?: string;
	showBackHome?: boolean;
	customMessage?: string;
};

function ErrorFallback({
	error,
	componentStack,
	showBackHome,
	customMessage,
}: Props) {
	const handleCopyToCliboard = () => {
		navigator.clipboard.writeText(componentStack || '');
		message.success('Error message copied to clipboard!');
	};

	return (
		<div className="flex h-screen flex-col items-center pt-12 font-medium">
			<Logo className="w-64" />
			<h1 className="my-2 text-4xl font-medium">Oops!</h1>
			<h2 className="my-2 w-4/12 text-center text-2xl font-medium">
				{customMessage || 'Something went wrong.'}
			</h2>
			<p className="my-0 text-center text-xl"></p>
			{!customMessage && (
				<>
					Please send the error message along with the last actions you took to:
					<p>ionut.morariu@theviralab.com</p>
				</>
			)}
			{customMessage && <p>Please contact: ionut.morariu@theviralab.com</p>}
			<p className="m-0">
				{' '}
				This will help solve the issue as fast as possible.
			</p>
			<pre className="max-h-64 w-5/12 overflow-y-auto rounded border border-solid border-gray-400 bg-gray-200 text-base">
				Error message: <br />
				{error.message}
				{componentStack}
			</pre>
			<Space>
				<Button onClick={handleCopyToCliboard} icon={<CopyOutlined />}>
					Copy error message
				</Button>
				{showBackHome && (
					<Button href="/" icon={<HomeOutlined />} type="primary">
						Go back home
					</Button>
				)}
			</Space>
		</div>
	);
}

export default ErrorFallback;
