import { Button, Form, type FormInstance } from 'antd';
import type { BaseButtonProps } from 'antd/es/button/button';
import React, { useEffect, useState } from 'react';

interface SubmitButtonProps extends BaseButtonProps {
	form: FormInstance;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
	form,
	children,
	...props
}) => {
	const [submittable, setSubmittable] = useState<boolean>(false);

	// Watch all values
	const values = Form.useWatch([], form);

	useEffect(() => {
		form
			.validateFields({ validateOnly: true })
			.then(() => setSubmittable(true))
			.catch(() => setSubmittable(false));
	}, [form, values]);

	return (
		<Button type="primary" htmlType="submit" disabled={!submittable} {...props}>
			{children}
		</Button>
	);
};

export default SubmitButton;
