import { ObjectiveData } from '@/lib/targetsApi';
import { getFormattedNumber } from '@/lib/utils';
import { formatMoney } from '@/utils/currency';
import { Table } from 'antd';

type Props = {
	quarters: string[];
	totals: Record<string, ObjectiveData>;
};

type AlignType = 'left' | 'right' | 'center';

const TargetTableSummary = ({ quarters, totals }: Props) => {
	const summaryColumns = quarters
		.map((q) => [
			{
				key: `${q}-target`,
				render: formatMoney(totals[q].target),
				align: 'right' as AlignType,
			},
			{
				key: `${q}-investment`,
				render: formatMoney(totals[q].investment),
				align: 'right' as AlignType,
			},
			{
				key: `${q}-sor`,
				render: getFormattedNumber(totals[q].sor * 100, 2) + '%',
				align: 'center' as AlignType,
			},
			{
				key: `${q}-targetPercentage`,
				render: getFormattedNumber(totals[q].targetPercentage * 100, 2) + '%',
				align: 'center' as AlignType,
			},
		])
		.flat();

	return (
		<Table.Summary.Row className="bg-gray-50">
			<Table.Summary.Cell index={0} colSpan={1} align="left">
				Total
			</Table.Summary.Cell>
			{summaryColumns.map((c, index) => {
				return (
					<Table.Summary.Cell key={c.key} index={index + 1} colSpan={1} align={c.align}>
						<span className="text-xs font-medium">{c.render}</span>
					</Table.Summary.Cell>
				);
			})}
		</Table.Summary.Row>
	);
};

export default TargetTableSummary;
