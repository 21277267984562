import { renderToString } from 'react-dom/server';
import ReactApexChart from 'react-apexcharts';
import CustomTooltip from '@/routes/MediaAnalysisPage/graphs/CustomTooltip';
import { getFormattedNumber } from '@/lib/utils';

type Props = {
	data: {
		agency: string;
		investment: number;
		cost: number;
		profit: number;
	}[];
};

const AgencyInvestment = ({ data }: Props) => {
	return (
		<ReactApexChart
			options={{
				legend: {
					show: true,
				},
				grid: {
					borderColor: '#D1D5DB',
					strokeDashArray: 2,
					yaxis: {
						lines: {
							show: true,
						},
					},
					xaxis: {
						lines: {
							show: true,
						},
					},
				},
				dataLabels: {
					enabled: false,
				},
				tooltip: {
					custom: function ({ series, seriesIndex, dataPointIndex, w }) {
						return renderToString(
							<CustomTooltip
								value={series[seriesIndex][dataPointIndex]}
								title={w.globals.labels[dataPointIndex]}
							/>
						);
					},
				},
				plotOptions: {
					bar: {
						borderRadius: 4,
						borderRadiusApplication: 'end',
						horizontal: false,
					},
				},
				xaxis: {
					categories: data.map((d) => d.agency),
				},
				yaxis: {
					labels: {
						formatter: function (val) {
							return getFormattedNumber(val, 1);
						},
					},
				},
				chart: {
					height: 300,
					type: 'bar',
					toolbar: {
						show: false,
					},
				},
			}}
			series={[
				{ name: 'Investment', data: data.map((d) => d.investment) },
				{ name: 'Profit', data: data.map((d) => d.profit) },
				{ name: 'Cost', data: data.map((d) => d.cost) },
			]}
			type="bar"
			height={300}
		/>
	);
};

export default AgencyInvestment;
