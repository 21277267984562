import { status } from '@/lib/constants';
import { UPDATE_REPORT_PLATFORM_STATUS_MUTATION } from '@/lib/reportsApi';
import { capitalize } from '@/lib/utils';
import { DownOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Dropdown, MenuProps } from 'antd';
import { FC } from 'react';

type Props = {
  currentStatus: string;
  platformId: string;
  refetch: any;
};

const EditStatusField: FC<Props> = ({ currentStatus, platformId, refetch }: Props) => {
  const [updateStatus] = useMutation(UPDATE_REPORT_PLATFORM_STATUS_MUTATION);
  const onClick = async (value: any) => {
    if (value.key !== currentStatus) {
      await updateStatus({ variables: { id: platformId, status: value.key } });
      refetch();
    }
  };

  const items: MenuProps['items'] = status.map((s) => ({
    key: s.status,
    label: capitalize(s.status),
  }));

  const getColor = () => {
    return status.find((s) => s.status === currentStatus)?.color;
  };

  return (
    <div className={`bg-${getColor()} cursor-pointer rounded px-3 py-1 text-white`}>
      <Dropdown menu={{ items, onClick }} trigger={['click']} className="w-full">
        <div className="flex justify-between">
          {capitalize(currentStatus)}
          <DownOutlined />
        </div>
      </Dropdown>
    </div>
  );
};

export default EditStatusField;
