import { formatMoney } from '@/utils/currency';
import { Table, type TableColumnsType } from 'antd';

type Props = {
	data: {
		platform: string;
		key: string;
		children: {
			format: string;
			key: string;
			children: {
				buyingType: string;
				key: string;
				rate: number;
				effectiveCost: number;
			}[];
		}[];
	}[];
};

interface DataType {
	platform: string;
	key: string;
	children: {
		format: string;
		key: string;
		children: {
			buyingType: string;
			key: string;
			rate: number;
			effectiveCost: number;
		}[];
	}[];
}

const BuyingTypeAverageTable = ({ data }: Props) => {
	const columns: TableColumnsType<DataType> = [
		{
			title: 'Platform',
			dataIndex: 'platform',
			key: 'platform',
		},
		{
			title: 'Format',
			dataIndex: 'format',
			key: 'format',
		},
		{
			title: 'Buying Type',
			dataIndex: 'buyingType',
			key: 'buyingType',
		},
		{
			title: 'EffectiveCost',
			dataIndex: 'effectiveCost',
			key: 'effectiveCost',
			render: (value) => {
				if (value !== undefined) {
					return formatMoney(value);
				}
			},
		},
		{
			title: 'Rate',
			dataIndex: 'rate',
			key: 'rate',
			render: (value) => {
				if (value !== undefined) {
					return formatMoney(value);
				}
			},
		},
	];

	return (
		<div>
			<Table<DataType>
				expandable={{
					defaultExpandAllRows: true,
				}}
				columns={columns}
				dataSource={data}
				size="small"
				pagination={false}
				bordered
			/>
		</div>
	);
};

export default BuyingTypeAverageTable;
