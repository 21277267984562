import { round2Decimals } from '@/lib/utils';
import { Form, Select } from 'antd';
import { FC, useEffect, useState } from 'react';

type Props = {
	onChange: (value: number | null) => void;
	initialValue?: any;
};

const ExchangeRateField: FC<Props> = (props: Props) => {
	const [exchangeRates, setExchangeRates] = useState<any[]>();
	const [selectedRate, setSelectedRate] = useState(
		props.initialValue?.rate || 1
	);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getRates();
	}, []);

	const getRates = async () => {
		setLoading(true);
		const res = await fetch(
			`${process.env.REACT_APP_TVL_CURRENCY_ENDPOINT}?apikey=${process.env.REACT_APP_TVL_API_KEY}`
		);
		const rates = await res.json();
		const usdRates = rates.items
			.filter(
				(r: any) =>
					r.destinationCurrency === 'USD' || r.originCurrency === 'USD'
			)
			.map((r: any) => {
				if (r.destinationCurrency === 'USD') {
					return { currency: r.originCurrency, rate: r.rate, id: r.id };
				} else {
					return {
						currency: r.destinationCurrency,
						rate: round2Decimals(1 / r.rate),
						id: r.id,
					};
				}
			})
			.filter(
				(value: any, index: number, self: any) =>
					index === self.findIndex((t: any) => t.currency === value.currency)
			);
		setExchangeRates(usdRates);
		setLoading(false);
	};

	const handleOnChange = async (value: any) => {
		const selected = exchangeRates?.find((r: any) => r.currency === value);
		props.onChange(selected.rate);
		setSelectedRate(selected.rate);
	};

	const prefixSelector = (
		<Form.Item
			name="currency"
			noStyle
			initialValue={props.initialValue?.currency || 'USD'}>
			<Select
				style={{ width: 220 }}
				loading={loading}
				optionFilterProp="country"
				onChange={handleOnChange}>
				{exchangeRates?.map((country: any) => (
					<Select.Option key={country.id} value={country.currency}>
						{country.currency}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
	return (
		<div style={{ display: 'flex' }}>
			{prefixSelector}
			<div className="exchangeRate"> {selectedRate} </div>
		</div>
	);
};

export default ExchangeRateField;
