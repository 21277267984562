import {
	FINANCES_QUERY,
	IFinancesQueryVariables,
	ReportsData,
} from '@/lib/reportsApi';
import { calculateQuarters, capitalize } from '@/lib/utils';
import { useQuery } from '@apollo/client';
import { FC, useState, useContext } from 'react';

import './styles.css';
import {
	Button,
	DatePicker,
	Form,
	Select,
	Skeleton,
	Table,
	TableColumnsType,
} from 'antd';
import { formatMoney } from '@/utils/currency';
import { processData } from './processData';
import dayjs from 'dayjs';
import { AuthContext } from '../../context/AuthContext';

type Props = {};

const FinancesPage: FC<Props> = (props: Props) => {
	const [type, setType] = useState('investment');
	const [startDate, setStartDate] = useState('2024-01-01');
	const [endDate, setEndDate] = useState('2024-12-31');

	const { user } = useContext(AuthContext);
	const country = user?.country?.name;

	const { data, loading, refetch } = useQuery<
		ReportsData,
		IFinancesQueryVariables
	>(FINANCES_QUERY, {
		variables: { startDate: startDate, endDate: endDate, country: country },
	});

	if (!data) {
		return (
			<>
				<Skeleton active />
				<Skeleton active />
				<Skeleton active />
			</>
		);
	}

	const quarters = calculateQuarters(startDate, endDate);

	const columns: TableColumnsType<any> = [
		{
			title: 'Row Label',
			dataIndex: 'rowLabel',
			key: 'rowLabel',
		},
		...quarters.map((q) => ({
			title: q,
			dataIndex: q,
			key: q,
			render: (record: any) => {
				console.log(type);
				if (record && record[type] !== 0) {
					return formatMoney(record[type]);
				}
				return '';
			},
			sorter: (a: any, b: any) => {
				const valA = a[q] !== undefined ? a[q][type] : 0;
				const valB = b[q] !== undefined ? b[q][type] : 0;
				return valA - valB;
			},
		})),
		{
			title: 'Total',
			dataIndex: 'rowLabel',
			key: 'total',
			render: (value, record) =>
				formatMoney(
					quarters.reduce((acc, c) => {
						if (!record[c]) return acc;
						return (acc += record[c][type]);
					}, 0)
				),
			sorter: (a: any, b: any) => {
				const sumA = quarters.reduce((acc, c) => {
					if (!a[c]) return acc;
					return (acc += a[c][type]);
				}, 0);
				const sumB = quarters.reduce((acc, c) => {
					if (!b[c]) return acc;
					return (acc += b[c][type]);
				}, 0);
				return sumA - sumB;
			},
		},
	];

	const tableData = processData(data.items, startDate, endDate);

	const filteredData = data.items.filter(
		(item) =>
			item.country.name === country ||
			(Array.isArray(country) &&
				country.some((c) => c.name === item.country.name))
	);

	const tableDataCountry = processData(filteredData, startDate, endDate);

	console.log(tableDataCountry);

	const handleFilter = (values: any) => {
		if (values.startDate && values.endDate) {
			setStartDate(values.startDate.add(4, 'hour').toISOString().split('T')[0]);
			setEndDate(values.endDate.add(4, 'hour').toISOString().split('T')[0]);
		}
		setType(values.type || 'investment');
		refetch();
	};

	return (
		<>
			<div className="mb-4">
				<h1 className="mb-1 text-2xl">Showing {capitalize(type)}</h1>
				<Form layout="inline" onFinish={handleFilter}>
					<Form.Item
						label="Start Date"
						name="startDate"
						initialValue={dayjs(startDate)}>
						<DatePicker className="w-full" />
					</Form.Item>
					<Form.Item
						label="End Date"
						name="endDate"
						initialValue={dayjs(endDate)}>
						<DatePicker className="w-full" />
					</Form.Item>
					<Form.Item label="Values" name="type" className="w-48">
						<Select
							placeholder="Type"
							options={[
								{ label: 'Investment', value: 'investment' },
								{ label: 'Billing', value: 'billing' },
							]}
						/>
					</Form.Item>
					<Form.Item>
						<Button key="submit" htmlType="submit" type="primary">
							Submit
						</Button>
					</Form.Item>
				</Form>
			</div>
			{user?.permission?.financeResultsSeePage === 'country' ? (
				<Table
					columns={columns}
					dataSource={tableDataCountry}
					size="small"
					className="financesTable shadow"
					rowKey="key"
					rowClassName={'text-[12px]'}
					pagination={false}
					loading={loading}
					bordered
					expandable={{
						defaultExpandedRowKeys: [0, '1-0'],
					}}
				/>
			) : (
				<Table
					columns={columns}
					dataSource={tableData}
					size="small"
					className="financesTable shadow"
					rowKey="key"
					rowClassName={'text-[12px]'}
					pagination={false}
					loading={loading}
					bordered
					expandable={{
						defaultExpandedRowKeys: [0, '1-0'],
					}}
				/>
			)}
		</>
	);
};

export default FinancesPage;
