import { Form, InputNumber, Select } from 'antd';
import { FC, useState } from 'react';

type Props = {
	prefixName: [number, string] | string;
	fieldName: [number, string] | string;
	initialValue?: {
		objectiveType: string;
		objectiveValue: number;
	};
	onChange?: (value: string) => void;
};

const ObjectiveTypeSelect: FC<Props> = ({
	initialValue,
	prefixName,
	fieldName,
	onChange,
}: Props) => {
	const handleSelectChange = (value: string) => {
		if (onChange) {
			onChange(value);
		}
	};

	const [objectiveType, setObjectiveType] = useState(
		initialValue?.objectiveType || 'budget'
	);

	const [objectiveValue, setObjectiveValue] = useState<number | null>(
		initialValue?.objectiveValue ?? null
	);

	const prefixSelector = (
		<Form.Item
			name={prefixName}
			noStyle
			initialValue={objectiveType}
			rules={[
				{
					required: true,
					message: 'Please select a valid objective type',
				},
			]}>
			<Select
				style={{ width: 120 }}
				onChange={(value) => {
					setObjectiveType(value);
					handleSelectChange(value);
				}}
				value={objectiveType}>
				<Select.Option value="budget">Budget</Select.Option>
				<Select.Option value="absolute">Absolute</Select.Option>
			</Select>
		</Form.Item>
	);

	return (
		<Form.Item
			name={fieldName}
			noStyle
			initialValue={objectiveValue}
			rules={[
				{
					required: objectiveType === 'absolute',
					message: 'Please input a valid objective value',
				},
				{
					type: 'number',
					min: 0,
					message: 'Objective value must be a positive number',
					transform: (value) => (value === '' ? null : value), // Convertir cadena vacía a null
				},
			]}>
			<InputNumber
				addonBefore={prefixSelector}
				style={{ width: '100%' }}
				value={objectiveValue}
				onChange={(value) =>
					setObjectiveValue(value !== undefined ? value : null)
				}
			/>
		</Form.Item>
	);
};

export default ObjectiveTypeSelect;
