import { SalespeopleData, SALESPEOPLE_QUERY } from '@/lib/listsApi';
import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { FC } from 'react';

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
};

const SalespersonSelect: FC<Props> = (props: Props) => {
  const { data, loading } = useQuery<SalespeopleData>(SALESPEOPLE_QUERY);

  return (
    <Select
      {...props}
      showSearch
      loading={loading}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {data?.salespeople.map((person) => (
        <Select.Option key={person.id} value={person.id}>
          {person.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SalespersonSelect;
