const getParsedValue = (v: string) => {
	if ((v.match(/,/g) || []).length >= 1 && (v.match(/\./g) || []).length <= 1) {
		//If pasted number is 123,456,789.15
		v = v.replace(/,/g, '');
	} else if ((v.match(/\./g) || []).length > 1) {
		//If pasted number is 123.456,15
		v = v.replace(/\./g, '').replace(',', '.');
	}

	const value = parseFloat(v);
	return value;
};

export { getParsedValue };
