import { FC, useContext } from 'react';
import FinancesDataTable from './FinancesDataTable';

import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

type Props = {};

const FinancesDataPage: FC<Props> = (props: Props) => {
	const { user } = useContext(AuthContext);

	return (
		<div>
			{user?.permission?.financeDataSeePage === 'yes' ? (
				<FinancesDataTable totalPages={15} />
			) : (
				<Navigate to="/" />
			)}
		</div>
	);
};

export default FinancesDataPage;
