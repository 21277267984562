import {
	DeletePlatformVariables,
	DELETE_PLATFORM_MUTATION,
} from '@/lib/reportsApi';
import { useMutation } from '@apollo/client';
import { Modal } from 'antd';

type Props = {
	platformId: string;
	refetch: any;
	refetchReports: any;
	open: boolean;
	setOpen: (newOpen: string) => void;
};

const DeletePlatform = ({
	platformId,
	refetch,
	open,
	setOpen,
	refetchReports,
}: Props) => {
	const [deletePlatformMutation, { loading }] = useMutation<
		any,
		DeletePlatformVariables
	>(DELETE_PLATFORM_MUTATION);
	const onConfirm = async () => {
		await deletePlatformMutation({ variables: { platformId } });
		await refetch();
		await refetchReports();
		setOpen('none');
	};

	return (
		<Modal
			title="Delete sub campaign"
			open={open}
			onOk={onConfirm}
			onCancel={() => setOpen('none')}
			confirmLoading={loading}>
			<p>Are you sure you want to delete this sub campaign?</p>
		</Modal>
	);
};

export default DeletePlatform;
