import React, { useState } from 'react'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { formatMoney } from '@/utils/currency'
import dayjs from 'dayjs'
import XLSX from 'sheetjs-style'

interface Report {
  id: string
  salesperson: { name: string }
  country: { name: string }
  agency: { name: string }
  client: { name: string }
  billing: number
  exchangeRate: number
  investment: number
  budget: number
  currency: string
  billingMonth: string
}

interface ExportCSVButtonProps {
  data: Report[]
  totalPages: number
  fetchDataForPage: (page: number) => Promise<Report[]>
  setShowMessage: Function
  setShowTable: Function
  resetToFirstPage: () => void
}

const ExportCSVButton: React.FC<ExportCSVButtonProps> = ({
  data,
  totalPages,
  setShowMessage,
  setShowTable,
  fetchDataForPage,
  resetToFirstPage,
}) => {
  const [loading, setLoading] = useState(false)

  const exportToCSV = async () => {
    setLoading(true)
    setShowMessage(true)
    setShowTable(false)

    try {
      const allData = await fetchAllData(totalPages)

      const exportData = allData.map((report) => ({
        column_type: 'campaign',
        c_id: report.id,
        c_salesperson: report.salesperson ? report.salesperson.name : 'N/A',
        c_country: report.country ? report.country.name : 'N/A',
        // c_market: report.country ? report.country.name : 'N/A',
        c_agency: report.agency ? report.agency.name : 'N/A',
        c_client: report.client ? report.client.name : 'N/A',
        // c_billing: formatMoney(report.billing, 'en-US', report.currency),
        c_billingLC: formatMoney(report.billing, 'en-US', report.currency),
        c_billingUSD: formatMoney(
          report.billing * report.exchangeRate,
          'en-US',
        ),
        // c_investment: formatMoney(report.investment, 'en-US', report.currency),
        c_investmentLC: formatMoney(
          report.investment,
          'en-US',
          report.currency,
        ),
        c_investmentUSD: formatMoney(
          report.investment * report.exchangeRate,
          'en-US',
        ),
        // c_budget: formatMoney(report.budget, 'en-US', report.currency),
        c_budgetLC: formatMoney(report.budget, 'en-US', report.currency),
        c_budgetUSD: formatMoney(report.budget * report.exchangeRate, 'en-US'),
        c_orderMonth: formatBillingMonth(report.billingMonth),
      }))

      const worksheet = XLSX.utils.json_to_sheet(exportData || [])
      const csvData = XLSX.utils.sheet_to_csv(worksheet)

      const currentDate = dayjs().format('YYYY-MM-DD')
      const fileName = `Report-editing-mode-${currentDate}.csv`

      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Failed to export data:', error)
    } finally {
      setLoading(false)
      setShowMessage(false)
      setShowTable(true)
      resetToFirstPage()
    }
  }

  const fetchAllData = async (totalPages: number): Promise<Report[]> => {
    const allData: Report[] = []
    for (let page = 1; page <= totalPages; page++) {
      const pageData = await fetchDataForPage(page)
      allData.push(...pageData)
    }
    return allData
  }

  const formatBillingMonth = (value: string): string => {
    if (value) {
      const formattedDate = dayjs(value)
      const currentYear = dayjs().year()
      const year =
        formattedDate.year() === 2001 ? currentYear : formattedDate.year()
      return formattedDate.format(`MMM-${year}`)
    } else {
      return 'N/A'
    }
  }

  return (
    <Button
      className="ml-1"
      type="default"
      icon={<DownloadOutlined />}
      onClick={exportToCSV}
      loading={loading}>
      {loading ? 'Exporting .csv' : `Download .csv`}
    </Button>
  )
}

export default ExportCSVButton
