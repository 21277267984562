import type { ExchangeRateItem } from '@/lib/tvlApiTypes';
import { round2Decimals } from '@/lib/utils';
import { createContext, useEffect, useState } from 'react';

type TvlApiContextType = {
	exchangeRates: ExchangeRateItem[];
};

type Props = {
	children: React.ReactNode;
};

export const TvlApiContext = createContext({} as TvlApiContextType);

const TvlApiProvider = ({ children }: Props) => {
	const [exchangeRates, setExchangeRates] = useState<ExchangeRateItem[]>([]);
	const getRates = async () => {
		const res = await fetch(
			`${process.env.REACT_APP_TVL_CURRENCY_ENDPOINT}?apikey=${process.env.REACT_APP_TVL_API_KEY}`
		);
		const rates = await res.json();
		console.log(rates);
		setExchangeRates(rates.items);
	};

	useEffect(() => {
		getRates();
	}, []);

	return (
		<TvlApiContext.Provider value={{ exchangeRates }}>
			{children}
		</TvlApiContext.Provider>
	);
};

export default TvlApiProvider;
