import { gql } from '@apollo/client';


export interface ReportPlatformAuditData {
	audits: {
		id: string;
		updatedField: string;
		user: {
			name: string;
		};
		newValue: string;
		oldValue: string;
		timestamp: string;
	}[];
}

export interface ReportPlatformAuditVariables {
	platform: string;
}

const REPORT_PLATFORM_AUDIT = gql`
	query REPORT_PLATFORM_AUDIT($platform: ID!) {
		audits: reportPlatformAudits(
			where: { updatedPlatform: { id: { equals: $platform } } }
			orderBy: { timestamp: desc }
		) {
			id
			updatedField
			user {
				name
			}
			newValue
			oldValue
			timestamp
		}
	}
`;

export interface ReportClientAuditData {
	audits: {
		id: string;
		updatedField: string;
		user: {
			name: string;
		};
		newValue: string;
		oldValue: string;
		timestamp: string;
	}[];
}

export interface ReportClientAuditVariables {
	client: string;
}

const REPORT_CLIENT_AUDIT = gql`
	query REPORT_CLIENT_AUDIT($client: ID!) {
		audits: reportAudits(
			where: { updatedReport: { id: { equals: $client } } }
			orderBy: { timestamp: desc }
		) {
			id
			updatedField
			user {
				name
			}
			newValue
			oldValue
			timestamp
		}
	}
`;

export { REPORT_PLATFORM_AUDIT, REPORT_CLIENT_AUDIT };
