import { StatisticsQueryData, STATISTICS_QUERY } from '@/lib/reportsApi';
import { useQuery } from '@apollo/client';
import { Card, Col, Row, Statistic } from 'antd';
import { FC, useContext } from 'react';

import { AuthContext } from '../../context/AuthContext';

type Props = {};

const HomePage: FC<Props> = (props: Props) => {
	const { loading, data } = useQuery<StatisticsQueryData>(STATISTICS_QUERY, {
		nextFetchPolicy: 'network-only',
	});

	const { user } = useContext(AuthContext);

	return (
		<Row gutter={16}>
			<Col span={4}>
				<Card>
					<Statistic loading={loading} title="Total campaigns" value={data?.totalReports} />
				</Card>
			</Col>
		</Row>
	);
};

export default HomePage;
