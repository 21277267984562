import { FC } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { ReactComponent as Logo } from '../assets/logo-light.svg';

type Props = {};


const ErrorRole: FC<Props> = (props: Props) => {
  return (
		<div className="flex h-screen flex-col items-center pt-12 font-medium">
			<Logo className="w-64" />
			<h1 className="my-2 text-4xl font-medium">Oops!</h1>
			<h2 className="my-2 w-4/12 text-center text-2xl font-medium">
				Something went wrong.
			</h2>
			<p className="my-0 text-center text-xl">
					Please send the error message along with the last actions you took to:
					<p>ionut.morariu@theviralab.com</p>
			</p>
      <pre className="max-h-64 w-5/12 overflow-y-auto rounded border border-solid border-gray-400 bg-gray-200 text-base">
				Error message: <br />
				The problem occurs because you have to assign a permission to the created user.<br />
        The Administrator must grant this permission to the user to fix this error. <br />
			</pre>
			<Space>
        <Button href="/" icon={<HomeOutlined />} type="primary">
          Go back home
        </Button>
			</Space>
		</div>
	);
}  


export default ErrorRole;