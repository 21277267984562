import {
	Button,
	Card,
	Col,
	DatePicker,
	Form,
	FormInstance,
	Row,
	Select,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_MEDIA_ANALYSIS_FILTERS } from '@/lib/MediaAnalysisApi';

type Props = {
	form: FormInstance;
	loading: boolean;
};

const businessModels = [
	'Tech fee',
	'Tech cost',
	'Rebate or fix price',
	'Fee over investment',
	'Performance Fee',
];

const buyingTypes = [
	'CPM',
	'CPC',
	'CPV',
	'CPE',
	'CPL',
	'CPA',
	'CPI',
	'CPD',
	'CPR',
];

const MediaAnalysisForm = ({ form, loading: queryLoading }: Props) => {
	const fields = Form.useWatch([], form);
	const [disabled, setDisabled] = useState(true);

	const { data, loading, error } = useQuery(GET_MEDIA_ANALYSIS_FILTERS);

	useEffect(() => {
		if (!fields) {
			return;
		}
		if (fields.dateRange !== undefined && fields.dateRange !== null) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [fields]);

	return (
		<Card>
			<Row gutter={32}>
				<Col span={8}>
					<Form.Item
						name="dateRange"
						rules={[
							{ required: true, message: 'Please select a valid date range' },
						]}
						label="Date range">
						<DatePicker.RangePicker picker="month" className="w-full" />
					</Form.Item>
					<Form.Item name="markets" label="Markets">
						<Select
							showSearch
							mode="multiple"
							optionFilterProp="label"
							options={data?.countries.map((c: any) => ({
								value: c.id,
								label: c.name,
							}))}
							loading={loading}
							className="w-full"
						/>
					</Form.Item>
					<Form.Item name="agencies" label="Agencies">
						<Select
							showSearch
							mode="multiple"
							optionFilterProp="label"
							options={data?.agencies.map((c: any) => ({
								value: c.id,
								label: c.name,
							}))}
							loading={loading}
							className="w-full"
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item name="clients" label="Clients">
						<Select
							showSearch
							mode="multiple"
							optionFilterProp="label"
							options={data?.clients.map((c: any) => ({
								value: c.id,
								label: c.name,
							}))}
							loading={loading}
							className="w-full"
						/>
					</Form.Item>
					<Form.Item name="platforms" label="Platforms">
						<Select
							showSearch
							mode="multiple"
							optionFilterProp="label"
							options={data?.platforms.map((c: any) => ({
								value: c.id,
								label: c.name,
							}))}
							loading={loading}
							className="w-full"
						/>
					</Form.Item>
					<Form.Item name="formats" label="Formats">
						<Select
							showSearch
							mode="multiple"
							optionFilterProp="label"
							options={data?.formats.map((c: any) => ({
								value: c.id,
								label: c.name,
							}))}
							loading={loading}
							className="w-full"
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item name="accountManagers" label="Account Managers">
						<Select
							showSearch
							mode="multiple"
							optionFilterProp="label"
							options={data?.accountManagers.map((c: any) => ({
								value: c.id,
								label: c.name,
							}))}
							loading={loading}
							className="w-full"
						/>
					</Form.Item>
					<Form.Item name="buyingTypes" label="Buying types">
						<Select
							mode="multiple"
							options={buyingTypes.map((b) => ({ value: b, label: b }))}
							optionFilterProp="label"
							loading={loading}
							className="w-full"
						/>
					</Form.Item>
					<Form.Item name="businessModels" label="Business Models">
						<Select
							mode="multiple"
							optionFilterProp="label"
							options={businessModels.map((b) => ({ value: b, label: b }))}
							loading={loading}
							className="w-full"
						/>
					</Form.Item>
				</Col>
			</Row>
			<Button
				type="primary"
				icon={<SearchOutlined />}
				disabled={disabled}
				loading={queryLoading}
				htmlType="submit">
				Search media analysis
			</Button>
		</Card>
	);
};

export default MediaAnalysisForm;
