import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { gql } from 'graphql.macro';
import { FC } from 'react';

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
};

interface BillingCompaniesData {
  billingCompanies: { name: string; id: string }[];
}

const BILLING_COMPANIES = gql`
  query {
    billingCompanies {
      id
      name
    }
  }
`;

const BillingCompanySelect: FC<Props> = (props: Props) => {
  const { data, loading } = useQuery<BillingCompaniesData>(BILLING_COMPANIES);

  return (
    <Select
      {...props}
      showSearch
      loading={loading}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    >
      {data?.billingCompanies.map((company) => (
        <Select.Option key={company.id} value={company.id}>
          {company.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default BillingCompanySelect;
