import { Report } from '@/interfaces/Report'
import { calculateQuarters } from '@/lib/utils'
import dayjs from 'dayjs'

interface QuarterData {
  investment: number
  billing: number
}

interface ReportData {
  key: number | string
  rowLabel: string
  children?: ReportData[]
  [key: string]: any
}

const createKeysForQuarters = (
  quarters: string[],
): { [key: string]: QuarterData } => {
  const result: { [key: string]: QuarterData } = {}
  for (const quarter of quarters) {
    result[quarter] = { investment: 0, billing: 0 }
  }
  return result
}

const addInvestmentBilling = (
  data: ReportData,
  key: string,
  investment: number,
  billing: number,
) => {
  const item = data[key] || { investment: 0, billing: 0 }
  data[key] = {
    investment: item.investment + investment,
    billing: item.billing + billing,
  }
}

const processData = (
  data: Report[],
  startDate: string,
  endDate: string,
): ReportData[] => {
  const quarters = calculateQuarters(startDate, endDate)
  const finalData = data.map((item, index) => ({
    ...item,
    key: index,
    country: item.country.name,
    agency: item.agency.name,
    client: item.client.name,
    salesperson: item.salesperson?.name,
    investment: item.investment * item.exchangeRate,
    billing: item.billing * item.exchangeRate,
    quarterKey: `Q${dayjs(item.startDate).quarter()}-${dayjs(
      item.startDate,
    ).year()}`,
  }))

  const result: ReportData[] = []

  finalData.forEach((current) => {
    let country = result.find((r) => r.rowLabel === current.country)
    if (!country) {
      country = {
        key: `country-${current.key}`,
        rowLabel: current.country,
        ...createKeysForQuarters(quarters),
        children: [],
      }
      result.push(country)
    }

    let agency = country.children!.find(
      (a: ReportData) => a.rowLabel === current.agency,
    )
    if (!agency) {
      agency = {
        rowLabel: current.agency,
        key: `agency-${current.key}`,
        ...createKeysForQuarters(quarters),
        children: [],
      }
      country.children!.push(agency)
    }

    let client = agency.children!.find(
      (c: ReportData) => c.rowLabel === current.client,
    )
    if (!client) {
      client = {
        rowLabel: current.client,
        key: `client-${current.key}`,
        ...createKeysForQuarters(quarters),
      }
      agency.children!.push(client)
    }

    addInvestmentBilling(
      client,
      current.quarterKey,
      current.investment,
      current.billing,
    )
    addInvestmentBilling(
      agency,
      current.quarterKey,
      current.investment,
      current.billing,
    )
    addInvestmentBilling(
      country,
      current.quarterKey,
      current.investment,
      current.billing,
    )
  })

  const totals: ReportData = {
    key: 'total',
    rowLabel: 'Grand Total',
    ...createKeysForQuarters(quarters),
  }

  result.forEach((country) => {
    quarters.forEach((quarter) => {
      totals[quarter].billing += country[quarter].billing
      totals[quarter].investment += country[quarter].investment
    })
  })

  result.push(totals)
  return result
}

export { processData, createKeysForQuarters }
