import { AllSelectsData, ALL_SELECTS_QUERY, BasicQueryData } from '@/lib/listsApi';
import { useQuery } from '@apollo/client';
import { createContext, FC, ReactNode } from 'react';

interface IFiltersContext {
	countries: BasicQueryData[];
	agencies: BasicQueryData[];
	salespeople: BasicQueryData[];
	accountManagers: BasicQueryData[];
	clients: BasicQueryData[];
	platforms: BasicQueryData[];
	planners: BasicQueryData[];
}

const FiltersContext = createContext<IFiltersContext>({
	countries: [],
	agencies: [],
	salespeople: [],
	accountManagers: [],
	clients: [],
	platforms: [],
	planners: [],
});

interface IFiltersContextProps {
	children?: ReactNode;
}

const FiltersProvider: FC<IFiltersContextProps> = ({ children }: IFiltersContextProps) => {
	const allSelectsQueryResult = useQuery<AllSelectsData>(ALL_SELECTS_QUERY);
	if (allSelectsQueryResult.loading) {
		return null;
	}
	const { countries, agencies, salespeople, accountManagers, clients, platforms, planners } =
		allSelectsQueryResult.data!;
	return (
		<FiltersContext.Provider
			value={{
				countries,
				agencies,
				salespeople,
				accountManagers,
				clients,
				platforms,
				planners,
			}}>
			{children}
		</FiltersContext.Provider>
	);
};

export { FiltersProvider, FiltersContext };
