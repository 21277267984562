import { FiltersContext } from '@/context/FiltersContext';
import { Select } from 'antd';
import { FC, useContext } from 'react';

type Props = {
	placeholder: string;
	onChange: (value: string) => void;
};

const PlannerSelect: FC<Props> = (props: Props) => {
	const { planners } = useContext(FiltersContext);
	return (
		<Select
			{...props}
			showSearch
			optionFilterProp="children"
			filterOption={(input, option) =>
				(option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
			}>
			{planners.map((planner) => (
				<Select.Option key={planner.id} value={planner.id}>
					{planner.name}
				</Select.Option>
			))}
		</Select>
	);
};

export default PlannerSelect;
