import CustomTooltip from '@/routes/MediaAnalysisPage/graphs/CustomTooltip';
import ReactApexChart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';

type Props = {
	data: {
		name: string;
		value: number;
	}[];
};

const CampaignsByPlatform = ({ data }: Props) => {
	return (
		<ReactApexChart
			options={{
				legend: {
					show: false,
				},
				grid: {
					borderColor: '#D1D5DB',
					strokeDashArray: 2,
					yaxis: {
						lines: {
							show: true,
						},
					},
					xaxis: {
						lines: {
							show: true,
						},
					},
				},
				tooltip: {
					custom: function ({ series, seriesIndex, dataPointIndex, w }) {
						return renderToString(
							<CustomTooltip
								value={series[seriesIndex][dataPointIndex]}
								title={w.globals.labels[dataPointIndex]}
							/>
						);
					},
				},
				plotOptions: {
					bar: {
						borderRadius: 4,
						borderRadiusApplication: 'end',
						horizontal: true,
					},
				},
				chart: {
					height: 350,
					type: 'bar',
					toolbar: {
						show: false,
					},
				},
			}}
			series={[
				{
					name: 'Campaigns by Platform',
					data: data.map((d) => ({ x: d.name, y: d.value })),
				},
			]}
			type="bar"
			height={350}
		/>
	);
};

export default CampaignsByPlatform;
