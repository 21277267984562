import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, InputNumber, Modal } from 'antd';
import CountriesSelect from '../DataPage/FormFields/CountrySelect';
import SalespersonSelect from '../DataPage/FormFields/SalespersonSelect';
import AgenciesSelect from '../DataPage/FormFields/AgencySelect';
import ExchangeRateField from '../DataPage/FormFields/ExchangeRateField';
import dayjs from 'dayjs';

import { useMutation } from '@apollo/client';
import {
	Target,
	UpdateSalespersonTargetData,
	UpdateSalespersonTargetVariables,
	UPDATE_TARGET_MUTATION,
} from '@/lib/targetsApi';

type Props = {
	refetch: any;
	record: Target;
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 18 },
	},
};

const EditTargetModal = ({ refetch, record }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [editTargetMutation] = useMutation<
		UpdateSalespersonTargetData,
		UpdateSalespersonTargetVariables
	>(UPDATE_TARGET_MUTATION);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const [form] = Form.useForm();

	const onFinish = async (values: any) => {
		console.log(record.id);
		await editTargetMutation({
			variables: {
				id: record.id,
				data: {
					salesperson: { connect: { id: values.salesperson } },
					country: { connect: { id: values.country } },
					agency: { connect: { id: values.agency } },
					year: `${values.year.year()}`,
					exchangeRate: values.exchangeRate,
					target: values.objective,
					currency: values.currency,
				},
			},
		});
		refetch();
		setIsModalOpen(false);
	};

	return (
		<div>
			<Button icon={<EditOutlined />} onClick={showModal} />
			<Modal
				width={600}
				bodyStyle={{ paddingTop: '20px' }}
				title={'Edit Objective'}
				open={isModalOpen}
				onOk={handleOk}
				destroyOnClose={true}
				onCancel={handleCancel}
				keyboard
				footer={[
					<Button danger>Cancel</Button>,
					<Button type="primary" onClick={form.submit}>
						Submit
					</Button>,
				]}>
				<Form {...formItemLayout} form={form} onFinish={onFinish}>
					<Form.Item
						label="Salesperson"
						required
						name="salesperson"
						initialValue={record.salesperson.id}>
						<SalespersonSelect
							placeholder="Select Salesperson"
							onChange={(value) => form.setFieldsValue({ salesperson: value })}
						/>
					</Form.Item>
					<Form.Item label="Country" required name="country" initialValue={record.country.id}>
						<CountriesSelect
							placeholder="Select Country"
							onChange={(value) => form.setFieldsValue({ country: value })}
						/>
					</Form.Item>
					<Form.Item label="Agency" required name="agency" initialValue={record.agency.id}>
						<AgenciesSelect
							placeholder="Select Agency"
							onChange={(value) => form.setFieldsValue({ agency: value })}
						/>
					</Form.Item>
					<Form.Item
						label="Year"
						name="year"
						required
						initialValue={dayjs({
							day: 1,
							month: 1,
							year: parseInt(record.year),
						})}>
						<DatePicker picker="year" className="w-full" />
					</Form.Item>
					<Form.Item label="Objective" name="objective" required initialValue={record.target}>
						<InputNumber
							className="w-full"
							onChange={(value) => {
								form.setFieldsValue({ objective: value });
							}}
						/>
					</Form.Item>
					<Form.Item
						label="Exchange Rate"
						name="exchangeRate"
						tooltip="Exchange rate from currency to USD. If currency is already USD, then rate is 1."
						rules={[{ required: true, message: 'Please input a valid quantity' }]}
						initialValue={record.exchangeRate}>
						<ExchangeRateField
							initialValue={{ currency: record.currency, rate: record.exchangeRate }}
							onChange={(value) => {
								form.setFieldsValue({ exchangeRateField: value });
							}}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default EditTargetModal;
