import React, { useState } from 'react';
import { Button } from 'antd';
import { formatMoney } from '@/utils/currency';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import XLSX from 'sheetjs-style';

interface ExportExcelButtonProps {
  data: any;
  queryVariables: any;
  refetch: any;
}

const ExportExcelButton: React.FC<
  ExportExcelButtonProps & {
    setShowMessageStyle: React.Dispatch<
      React.SetStateAction<React.CSSProperties>
    >;
    setShowTableStyle: React.Dispatch<
      React.SetStateAction<React.CSSProperties>
    >;
  }
> = ({
  data,
  queryVariables,
  refetch,
  setShowMessageStyle,
  setShowTableStyle,
}) => {
  const [loading, setLoading] = useState(false);

  const exportToExcel = async () => {
    setLoading(true);

    if (data && data.count && data.items) {
      const pageSize = 80;
      const totalPages = Math.ceil(data.count / pageSize);

      setShowMessageStyle({ display: 'block' });
      setShowTableStyle({ display: 'none' });

      let allItems: any[] = [];

      for (let page = 1; page <= totalPages; page++) {
        const response = await refetch({
          ...queryVariables,
          take: pageSize,
          skip: pageSize * (page - 1),
        });
        allItems = allItems.concat(response.data?.items || []);
      }

      const excelData = allItems.map((item) => ({
        // GID: item.id,
        Agency: item.agency.name,
        Client: item.client.name,
        Country: item.country.name,
        Campaign: item.campaign,
        BillingLC: formatMoney(item.billing, 'en-US', item.currency),
        BillingUSD: formatMoney(item.billing * item.exchangeRate),
        InvestmentLC: formatMoney(item.investment, 'en-US', item.currency),
        InvestmentUSD: formatMoney(item.investment * item.exchangeRate),
        BudgetLC: formatMoney(item.budget, 'en-US', item.currency),
        BudgetUSD: formatMoney(item.budget * item.exchangeRate),
        OrderNumber: item.orderNumber || 'S/N',
        BillNumber: item.billNumber || 'S/N',
        OrderMonth: item.billingMonth
          ? item.billingMonth.includes('-')
            ? item.billingMonth.replace(
                /-(\d{2})\b/g,
                (_: any, year: string) => `-${parseInt(year) + 2000}`,
              )
            : item.billingMonth
          : 'N/A',
        BillingDate: item.billingDate || 'N/A',
      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);

      const columnWidths = [
        { wch: 40 },
        // { wch: 20 },
        { wch: 30 },
        { wch: 20 },
        { wch: 40 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 30 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
      ];

      worksheet['!cols'] = columnWidths;

      const rowHeights = excelData.map(() => ({ hpx: 25 }));
      worksheet['!rows'] = rowHeights;
      worksheet['!rows'].push({ hpx: 25 });

      const headerCellStyle = {
        font: {
          name: 'Arial',
          sz: 13,
          bold: true,
          color: { rgb: '000000' },
        },
        fill: {
          fgColor: { rgb: 'DFDFDF' },
        },
        border: {
          top: { style: 'thin', color: { rgb: 'DFDFDF' } },
          bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
          left: { style: 'thin', color: { rgb: 'DFDFDF' } },
          right: { style: 'thin', color: { rgb: 'DFDFDF' } },
        },
        alignment: { horizontal: 'center', vertical: 'center' },
      };

      const columnCount =
        excelData.length > 0 ? Object.keys(excelData[0]).length : 0;
      const columnRange = Array.from({ length: columnCount }, (_, index) =>
        XLSX.utils.encode_cell({ r: 0, c: index }),
      );

      columnRange.forEach((cell) => {
        worksheet[cell].s = headerCellStyle;
      });

      const cellStyles = {
        blackRow: {
          font: {
            name: 'Arial',
            sz: 11,
            bold: false,
            color: { rgb: '000000' },
          },
          fill: {
            fgColor: { rgb: 'FFFFFF' },
          },
          border: {
            top: { style: 'thin', color: { rgb: 'DFDFDF' } },
            bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
            left: { style: 'thin', color: { rgb: 'DFDFDF' } },
            right: { style: 'thin', color: { rgb: 'DFDFDF' } },
          },
          alignment: { horizontal: 'center', vertical: 'center' },
        },
        whiteRow: {
          font: {
            name: 'Arial',
            sz: 11,
            bold: false,
            color: { rgb: '000000' },
          },
          fill: {
            fgColor: { rgb: 'DFDFDF' },
          },
          border: {
            top: { style: 'thin', color: { rgb: 'DFDFDF' } },
            bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
            left: { style: 'thin', color: { rgb: 'DFDFDF' } },
            right: { style: 'thin', color: { rgb: 'DFDFDF' } },
          },
          alignment: { horizontal: 'center', vertical: 'center' },
        },
      };

      excelData.forEach((rowData, rowIndex) => {
        const cellStyle =
          rowIndex % 2 === 0 ? cellStyles.blackRow : cellStyles.whiteRow;
        Object.keys(rowData).forEach((key, colIndex) => {
          const cellAddress = XLSX.utils.encode_cell({
            r: rowIndex + 1,
            c: colIndex,
          });
          worksheet[cellAddress].s = cellStyle;
        });
      });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Financial Data');
      const currentDate = dayjs().format('YYYY-MM-DD');
      const fileName = `FinancialData_${currentDate}.xlsx`;
      XLSX.writeFile(workbook, fileName);

      setLoading(false);

      setShowMessageStyle({ display: 'none' });
      setShowTableStyle({ display: 'block' });
    }
  };

  return (
    <Button
      className="ml-1"
      type="primary"
      icon={<DownloadOutlined />}
      onClick={exportToExcel}
      loading={loading}>
      {loading ? 'Exporting .xlsx' : 'Download .xlsx'}
    </Button>
  );
};

export default ExportExcelButton;
