import React, { useState } from 'react';
import { Button } from 'antd';
import { formatMoney } from '@/utils/currency';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import XLSX from 'sheetjs-style';

interface ExportExcelButtonProps {
	data: any;
	queryVariables: any;
	refetch: any;
	filterColumns: any;
}

const ExportExcelButton: React.FC<
	ExportExcelButtonProps & {
		setShowMessageStyle: React.Dispatch<
			React.SetStateAction<React.CSSProperties>
		>;
		setShowTableStyle: React.Dispatch<
			React.SetStateAction<React.CSSProperties>
		>;
		filterColumns: any;
	}
> = ({
	data,
	queryVariables,
	refetch,
	setShowMessageStyle,
	setShowTableStyle,
	filterColumns,
}) => {
	const [loading, setLoading] = useState(false);

	const formatDate = (dateString: string) => {
		if (!dateString) return 'N/A';
		return dateString.includes('-')
			? dateString.replace(
					/-(\d{2})\b/g,
					(_: any, year: string) => `-${parseInt(year, 10) + 2000}`
			  )
			: dateString;
	};

	const itemPresent = [
		'gluCampaignID',
		'agency',
		'client',
		'country',
		'campaign',
		'accountManager',
		'planner',
		'startDate',
		'endDate',
		'campaignDays',
		'activeDays',
		'spend',
		'market',
		'investment',
		'objectiveCost',
		'totalDelivery',
		'dateDelivery',
		'billingLC',
		'billingUSD',
		'investmentLC',
		'investmentUSD',
		'budgetLC',
		'budgetUSD',
		'orderNumber',
		'billNumber',
		'orderMonth',
		'billingDate',
		'status',
	];

	const platformColumns = [
		'SubCampaignName',
		'SubCampaignPlatform',
		'SubCampaignFormats',
		'SubCampaignStatus',
		'SubCampaignBuyingType',
		'SubCampaignRate',
		'SubCampaignObjectiveType',
		'SubCampaignObjectiveValue',
		'SubCampaignObjectiveSpend',
		'SubCampaignObjectiveEffectiveCost',
		'SubCampaignSecondaryKPIType',
		'SubCampaignSecondaryKPIValue',
		'SubCampaignSecondaryKPISpend',
		'SubCampaignSecondaryKPIEffectiveCost',
		'SubCampaignCost',
		'SubCampaignInvestment',
		'SubCampaignBudget',
		'SubCampaignDevices',
		'SubCampaignOtherVariables',
		'SubCampaignBusinessModel',
		'SubCampaignFee',
	];

	const createExportItems = (allItems: any[]) => {
		const expandedItems: any[] = [];

		const exportColumns = [
			...itemPresent.filter((column) => filterColumns.includes(column)),
			...platformColumns,
		];

		const exportColumnsRenamed = exportColumns.map((column) =>
			column === 'objectiveCost' ? 'benchmarkCost' : column
		);

		allItems.forEach((item) => {
			const commonFields = {
				gluCampaignID: item.id,
				campaign: item.campaign,
				agency: item.agency.name,
				country: item.country.name,
				market: item.country.name,
				client: item.client.name,
				accountManager: item.accountManager.name,
				planner: item.planner?.name || 'N/A',
				startDate: item.startDate,
				endDate: item.endDate,
				campaignDays: item.campaignDays || 'N/A',
				activeDays: item.activeDays || 'N/A',
				spend: item.spend || '$0.00',
				investment: item.investment,
				totalDelivery: item.totalDelivery || 'N/A',
				// dateDelivery: item.dateDelivery || 'N/A',
				// objectiveCost: item.objectiveCost || 'N/A',
				benchmarkCost: item.objectiveCost || 'N/A',
				billingLC: formatMoney(item.billing, 'en-US', item.currency),
				billingUSD: formatMoney(item.billing * item.exchangeRate),
				investmentLC: formatMoney(item.investment, 'en-US', item.currency),
				investmentUSD: formatMoney(item.investment * item.exchangeRate),
				budgetLC: formatMoney(item.budget, 'en-US', item.currency),
				budgetUSD: formatMoney(item.budget * item.exchangeRate),
				orderNumber: item.orderNumber || 'S/N',
				billNumber: item.billNumber || 'S/N',
				orderMonth: formatDate(item.billingMonth),
				billingDate: item.billingDate || 'N/A',
			};

			const platforms =
				item.platforms && item.platforms.length > 0 ? item.platforms : [{}];

			platforms.forEach((platform: any) => {
				const platformFields = {
					SubCampaignName: platform.name || 'N/A',
					SubCampaignPlatform: platform.platform?.name || 'N/A',
					SubCampaignFormats: platform.formats
						? platform.formats.map((format: any) => format.name).join(', ')
						: 'N/A',
					SubCampaignStatus: platform.status || 'N/A',
					SubCampaignBuyingType: platform.buyingType || 'N/A',
					SubCampaignRate: platform.rate || 'N/A',
					SubCampaignObjectiveType: platform.objectiveType || 'N/A',
					SubCampaignObjectiveValue: platform.objectiveValue || 'N/A',
					SubCampaignObjectiveSpend: platform.objectiveSpend || 'N/A',
					SubCampaignObjectiveEffectiveCost:
						platform.objectiveEffectiveCost || 'N/A',
					SubCampaignSecondaryKPIType: platform.secondaryKPIType || 'N/A',
					SubCampaignSecondaryKPIValue: platform.secondaryKPIValue || 'N/A',
					SubCampaignSecondaryKPISpend: platform.secondaryKPISpend || 'N/A',
					SubCampaignSecondaryKPIEffectiveCost:
						platform.secondaryKPIEffectiveCost || 'N/A',
					SubCampaignCost: platform.cost || 'N/A',
					SubCampaignInvestment: platform.investment || 'N/A',
					SubCampaignBudget: platform.budget || 'N/A',
					SubCampaignDevices: platform.device || 'N/A',
					SubCampaignOtherVariables: platform.otherVariable || 'N/A',
					SubCampaignBusinessModel: platform.businessModels || 'N/A',
					SubCampaignFee: platform.fee || 'N/A',
				};

				// const exportItem = { ...commonFields, ...platformFields }
				// const filteredExportItem = Object.fromEntries(
				//   Object.entries(exportItem).filter(([key]) =>
				//     exportColumns.includes(key),
				//   ),
				// )

				const exportItem = { ...commonFields, ...platformFields };
				const filteredExportItem = Object.fromEntries(
					Object.entries(exportItem).filter(([key]) =>
						exportColumnsRenamed.includes(key)
					)
				);

				expandedItems.push(filteredExportItem);
			});
		});

		return expandedItems;
	};

	const styleWorksheet = (worksheet: XLSX.WorkSheet, expandedItems: any[]) => {
		const columnWidths = new Array(42).fill({ wch: 40 });
		worksheet['!cols'] = columnWidths;

		const rowHeights = expandedItems.map(() => ({ hpx: 25 }));
		worksheet['!rows'] = rowHeights;
		worksheet['!rows'].push({ hpx: 25 });

		const headerCellStyle = {
			font: { name: 'Arial', sz: 13, bold: true, color: { rgb: '000000' } },
			fill: { fgColor: { rgb: 'DFDFDF' } },
			border: {
				top: { style: 'thin', color: { rgb: 'DFDFDF' } },
				bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
				left: { style: 'thin', color: { rgb: 'DFDFDF' } },
				right: { style: 'thin', color: { rgb: 'DFDFDF' } },
			},
			alignment: { horizontal: 'center', vertical: 'center' },
		};

		const columnCount =
			expandedItems.length > 0 ? Object.keys(expandedItems[0]).length : 0;
		const columnRange = Array.from({ length: columnCount }, (_, index) =>
			XLSX.utils.encode_cell({ r: 0, c: index })
		);

		columnRange.forEach((cell) => {
			worksheet[cell].s = headerCellStyle;
		});

		const cellStyles = {
			blackRow: {
				font: { name: 'Arial', sz: 11, bold: false, color: { rgb: '000000' } },
				fill: { fgColor: { rgb: 'FFFFFF' } },
				border: {
					top: { style: 'thin', color: { rgb: 'DFDFDF' } },
					bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
					left: { style: 'thin', color: { rgb: 'DFDFDF' } },
					right: { style: 'thin', color: { rgb: 'DFDFDF' } },
				},
				alignment: { horizontal: 'center', vertical: 'center' },
			},
			whiteRow: {
				font: { name: 'Arial', sz: 11, bold: false, color: { rgb: '000000' } },
				fill: { fgColor: { rgb: 'DFDFDF' } },
				border: {
					top: { style: 'thin', color: { rgb: 'DFDFDF' } },
					bottom: { style: 'thin', color: { rgb: 'DFDFDF' } },
					left: { style: 'thin', color: { rgb: 'DFDFDF' } },
					right: { style: 'thin', color: { rgb: 'DFDFDF' } },
				},
				alignment: { horizontal: 'center', vertical: 'center' },
			},
		};

		expandedItems.forEach((rowData, rowIndex) => {
			const cellStyle =
				rowIndex % 2 === 0 ? cellStyles.blackRow : cellStyles.whiteRow;
			Object.keys(rowData).forEach((key, colIndex) => {
				const cellAddress = XLSX.utils.encode_cell({
					r: rowIndex + 1,
					c: colIndex,
				});
				worksheet[cellAddress].s = cellStyle;
			});
		});
	};

	const exportToExcel = async () => {
		setLoading(true);

		if (data && data.count && data.items) {
			const pageSize = 10;
			const totalPages = Math.ceil(data.count / pageSize);

			setShowMessageStyle({ display: 'block' });
			setShowTableStyle({ display: 'none' });

			let allItems: any[] = [];

			for (let page = 1; page <= totalPages; page++) {
				const response = await refetch({
					...queryVariables,
					take: pageSize,
					skip: pageSize * (page - 1),
				});
				allItems = allItems.concat(response.data?.items || []);
			}

			const expandedItems = createExportItems(allItems);
			const worksheet = XLSX.utils.json_to_sheet(expandedItems);

			styleWorksheet(worksheet, expandedItems);

			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Client Services Data');
			const currentDate = dayjs().format('YYYY-MM-DD');
			const fileName = `ClientServicesData_${currentDate}.xlsx`;
			XLSX.writeFile(workbook, fileName);

			setLoading(false);
			setShowMessageStyle({ display: 'none' });
			setShowTableStyle({ display: 'block' });
		}
	};

	return (
		<Button
			className="ml-1"
			type="primary"
			icon={<DownloadOutlined />}
			onClick={exportToExcel}
			loading={loading}>
			{loading ? 'Exporting .xlsx' : 'Download .xlsx'}
		</Button>
	);
};

export default ExportExcelButton;
