import { secondaryKPIs } from '@/lib/constants';
import { Form, InputNumber, Select } from 'antd';
import { FC } from 'react';

type Props = {
	prefixName: [number, string] | string;
	fieldName: [number, string] | string;
	initialValue?: any;
};

const SecondaryKPIField: FC<Props> = ({ initialValue, prefixName, fieldName }: Props) => {
	const prefixSelector = (
		<Form.Item initialValue={initialValue?.secondaryKPIType} name={prefixName} noStyle>
			<Select defaultActiveFirstOption style={{ width: 120 }}>
				{secondaryKPIs.map((kpi) => (
					<Select.Option key={kpi} value={kpi}>
						{kpi}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);

	return (
		<Form.Item initialValue={initialValue?.secondaryKPIValue} noStyle name={fieldName}>
			<InputNumber addonBefore={prefixSelector} style={{ width: '100%' }} />
		</Form.Item>
	);
};

export default SecondaryKPIField;
