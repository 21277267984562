import {
	AllSelectsData,
	ALL_SELECTS_QUERY,
	CreateSelectDataMutationVariables,
	CREATE_SELECT_DATA_MUTATION,
} from '@/lib/listsApi';
import {
	CreateOnlyReportsVariables,
	CREATE_REPORTS_MUTATION,
} from '@/lib/reportsApi';
import { capitalize } from '@/lib/utils';
import { FileOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Popconfirm, Progress, Table, Upload } from 'antd';
import { parse } from 'papaparse';
import { FC, useState } from 'react';
import {
	checkMandatoryFields,
	checkRelationshipFields,
	checkFieldValidation,
	getReportData,
	getDescription,
} from './processData';
import UploadItem from './UploadItem';

type Props = {
	refetch: any;
};

type FileInfoState = {
	read: boolean;
	lines: number;
	fields?: string[];
	fieldsTypes: any[];
};

const ImportCSVForm: FC<Props> = (props: Props) => {
	const [fileInfo, setFileInfo] = useState<FileInfoState>({
		read: false,
		lines: 0,
		fieldsTypes: [],
	});
	const [parsedInfo, setParsedInfo] = useState<Record<string, any>[]>([]);
	const [parsedError, setParsedError] = useState<any[]>();

	const [emptyMandatoryFields, setMandatoryFields] = useState({
		state: false,
		data: {},
	});
	const [needsManagerReview, setManagerReview] = useState({
		state: false,
		data: {},
	});
	const [needsFieldReview, setFieldReview] = useState({
		state: false,
		data: {},
	});

	const [progressState, setProgressState] = useState({
		state: '',
		progress: 0,
	});

	const selectQueryData = useQuery<AllSelectsData>(ALL_SELECTS_QUERY);
	const [createReportsMutation] = useMutation<any, CreateOnlyReportsVariables>(
		CREATE_REPORTS_MUTATION
	);

	const handleFile = async (file: File) => {
		const string = await file.text();
		const parsed = parse<Record<string, any>[]>(string, {
			delimiter: ',',
			header: true,
			dynamicTyping: true,
		});
		const fieldsTypes = Object.entries(parsed.data[0]).map(
			([key, value], index) => ({
				key,
				value: value ? typeof value : 'string',
			})
		);
		setFileInfo({
			read: true,
			lines: parsed.data.length,
			fields: parsed.meta.fields || [],
			fieldsTypes,
		});
		setProgressState({ state: '', progress: 0 });
		setMandatoryFields({ state: false, data: {} });
		setManagerReview({ state: false, data: {} });
		setFieldReview({ state: false, data: {} });
		setParsedInfo(parsed.data);
		if (!selectQueryData.data || parsed.data.length <= 0) return false;
		if (parsed.errors.length > 0) {
			setParsedError(parsed.errors);
		}

		const mandatoryFields = checkMandatoryFields(parsed.data);
		let mandatory =
			Object.entries(mandatoryFields).reduce(
				(acc, curr) => acc + curr[1].length,
				0
			) > 0;

		const neededRelationshipFields = checkRelationshipFields(
			parsed.data,
			selectQueryData.data,
			mandatory
		);
		const needsFieldValidation = checkFieldValidation(parsed.data, mandatory);

		let needed =
			Object.entries(neededRelationshipFields).reduce(
				(acc, curr) => acc + curr[1].length,
				0
			) > 0;
		let reviewFields =
			Object.entries(needsFieldValidation).reduce(
				(acc, curr) => acc + curr[1].length,
				0
			) > 0;

		if (mandatory) {
			setMandatoryFields({ state: true, data: mandatoryFields });
		} else {
			if (needed) {
				setManagerReview({ state: needed, data: neededRelationshipFields });
			}
			if (reviewFields) {
				setFieldReview({
					state: reviewFields,
					data: needsFieldValidation,
				});
			}
		}

		return false;
	};

	const onConfirmImport = async () => {
		const allSelects = await selectQueryData.refetch();
		setProgressState({ state: 'ongoing', progress: 0 });
		const reportData = getReportData(parsedInfo, selectQueryData.data);
		const chunkSize = 100;
		const chunks = [];
		for (let i = 0; i < reportData.length; i += chunkSize) {
			const chunk = reportData.slice(i, i + chunkSize);
			chunks.push(chunk);
		}
		for (let c of chunks) {
			await createReportsMutation({ variables: { data: c } });
			setProgressState((prev) => ({
				state: 'ongoing',
				progress: Math.min(prev.progress + 100 / chunks.length, 100),
			}));
		}
		props.refetch();
		setProgressState({ state: 'ended', progress: 100 });
	};

	return (
		<div>
			<Upload
				onRemove={() => {
					setFileInfo({ read: false, lines: 0, fields: [], fieldsTypes: [] });
				}}
				accept=".csv"
				multiple={false}
				maxCount={1}
				itemRender={(item, file, fileList, actions) => (
					<UploadItem file={file} actions={actions} />
				)}
				beforeUpload={handleFile}>
				<Button type="text" icon={<FileOutlined />}>
					Select CSV File{' '}
					{/* <small className="ml-2">
            <b>(to add new information)</b>
          </small> */}
				</Button>
			</Upload>
			{fileInfo.read && (
				<>
					{parsedError && (
						<div className="my-2">
							<Alert
								type="error"
								message="CSV Parsing Error"
								closable
								description={
									<>
										{parsedError?.map((e) => {
											return (
												<div className="text-sm">
													Error on row {e.row + 1}: {e.message}
												</div>
											);
										})}
									</>
								}
							/>
						</div>
					)}
					<>
						{emptyMandatoryFields.state && (
							<div className="my-2">
								<Alert
									type="error"
									message="Mandatory fields are empty"
									closable
									description={
										<>
											<p>
												{' '}
												Some mandatory fields are missing. To be able to import
												this file, please fill the following fields:{' '}
											</p>
											<ul>
												{Object.entries(emptyMandatoryFields.data).map(
													(item: any) => {
														if (item[1].length > 0) {
															return (
																<li key={item[0]}>
																	<span
																		style={{
																			fontWeight: 'bold',
																		}}>{`${capitalize(item[0])}`}</span>
																	{` :  ${item[1]
																		.map((f: any) => f)
																		.join(', ')}`}
																</li>
															);
														}
													}
												)}
											</ul>
										</>
									}
								/>
							</div>
						)}
					</>
					<>
						{needsManagerReview.state && (
							<div className="my-2">
								<Alert
									type="warning"
									message="Following items can not be uploaded"
									closable
									description={
										<div>
											<p>
												If you want to import this file, please contact your
												manager. Following campaigns don’t exist in GluStudio:
											</p>
											<ul>
												{Object.entries(needsManagerReview.data).map(
													(item: any) => {
														if (item[1].length > 0) {
															return (
																<li key={item[0]}>
																	<span
																		style={{
																			fontWeight: 'bold',
																		}}>{`${capitalize(item[0])}`}</span>
																	{` :  ${item[1]
																		.map((r: any) => r.name)
																		.join(', ')}`}
																</li>
															);
														}
													}
												)}
											</ul>
										</div>
									}
								/>
							</div>
						)}
					</>
					<>
						{needsFieldReview.state && (
							<div className="my-2">
								<Alert
									type="info"
									message="Following fields need review before uploading csv"
									closable
									description={
										<div>
											<p>
												If you want to import this file, please review the
												following fields:
											</p>
											<ul>
												{Object.entries(needsFieldReview.data).map(
													(item: any) => {
														if (item[1].length > 0) {
															const description = getDescription(item[0]);
															return (
																<li key={item[0]}>
																	<span style={{ fontWeight: 'bold' }}>
																		{`${capitalize(item[0])} (${description}):`}
																		<br />
																	</span>
																	<ul>
																		{item[1].map((r: any) => (
																			<li key={item[1]}> {r.name} </li>
																		))}
																	</ul>
																</li>
															);
														}
													}
												)}
											</ul>
										</div>
									}
								/>
							</div>
						)}
					</>
					<Alert
						showIcon
						type="success"
						message=" File read correctly"
						description={
							<>
								<p>
									{fileInfo.lines} lines were found with{' '}
									{fileInfo.fields?.length} fields:
								</p>
								<Table
									size="small"
									bordered
									className="mb-4"
									scroll={{ y: 100 }}
									pagination={false}
									rowKey="key"
									rowClassName={'text-[12px]'}
									columns={[
										{ title: 'Column', key: 'column', dataIndex: 'key' },
										{
											title: 'Type',
											key: 'type',
											dataIndex: 'value',
											render: (val) => <pre className="mb-0">{val}</pre>,
										},
									]}
									dataSource={fileInfo.fieldsTypes}
								/>
								<p>
									Press the <b>Begin import</b> button start the import process.
								</p>
							</>
						}
					/>
					<Popconfirm
						className="mt-2 w-full"
						placement="top"
						title="Import CSV"
						onConfirm={onConfirmImport}
						disabled={progressState.state === 'ongoing'}
						okText="Yes"
						cancelText="No">
						<Button
							type="primary"
							className="w-full"
							style={{ width: '100%' }}
							loading={progressState.state === 'ongoing'}
							disabled={
								needsManagerReview.state ||
								emptyMandatoryFields.state ||
								needsFieldReview.state
							}>
							Begin import
						</Button>
					</Popconfirm>
					{(progressState.state === 'ongoing' ||
						progressState.state === 'ended') && (
						<div className="mt-2 mr-0 w-full">
							<Progress
								percent={Math.round(progressState.progress)}
								status={progressState.state === 'ended' ? 'success' : 'active'}
							/>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default ImportCSVForm;
