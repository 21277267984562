import { otherVariables } from '@/lib/constants';
import { Select } from 'antd';
import { FC } from 'react';

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
};

const OtherVariablesSelect: FC<Props> = (props: Props) => {
  return (
    <Select
      {...props}
      showSearch
      mode="multiple"
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    >
      {otherVariables.map((variable) => (
        <Select.Option key={variable} value={variable}>
          {variable}
        </Select.Option>
      ))}
    </Select>
  );
};

export default OtherVariablesSelect;
