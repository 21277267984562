import { ImportOutlined } from '@ant-design/icons';
import { Space, Button, Modal } from 'antd';
import { FC, useState, useContext } from 'react';
import ImportCSVForm from './ImportCSVForm';
import UpdateCSVForm from './UpdateCSVForm';
import { FileOutlined } from '@ant-design/icons';
import { Report } from '@/interfaces/Report';

import { AuthContext } from '../../context/AuthContext';

type Props = {
	report?: Report;
	refetch: any;
};

const ImportCSVModal: FC<Props> = (props: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [activeForm, setActiveForm] = useState<'import' | 'update' | null>(
		null
	);

	const { user } = useContext(AuthContext);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setActiveForm(null);
	};

	const handleImportClick = () => {
		setActiveForm('import');
	};

	const handleUpdateClick = () => {
		setActiveForm('update');
	};

	// const handleBackClick = () => {
	//   setActiveForm(null)
	// }

	return (
		<div className="mb-4">
			<Button
				size="middle"
				type="primary"
				icon={<ImportOutlined />}
				onClick={showModal}
				style={{
					display:
						user?.permission?.rowDataImportCsv === 'no' ? 'none' : 'block',
				}}>
				Import CSV
			</Button>
			<Modal
				width={650}
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				keyboard
				footer={''}>
				<h3>Upload CSV</h3>
				<p className="text-sm">
					Select a CSV file to import. Make sure the columns are separated by
					commas (<code>,</code>) and the decimal delimiter is set to point (
					<code>.</code>)
				</p>
				<div>
					<Space>
						<div className="mt-4 flex items-center justify-center space-x-2">
							{activeForm === null && (
								<>
									{user?.permission?.rowDataUpdateCsv === 'yes' ? (
										<>
											<Button
												type="text"
												onClick={handleImportClick}
												icon={<FileOutlined />}>
												Import New Data
											</Button>
											<span className="font-bold">or</span>
											<Button
												type="text"
												onClick={handleUpdateClick}
												icon={<FileOutlined />}>
												Update Existing Data
											</Button>
										</>
									) : (
										<ImportCSVForm refetch={props.refetch} />
									)}
								</>
							)}
						</div>
						{activeForm === 'import' && (
							<ImportCSVForm refetch={props.refetch} />
						)}
						{/* {activeForm === 'update' && props.report && ( */}
						{activeForm === 'update' && (
							<UpdateCSVForm refetch={props.refetch} />
						)}
					</Space>
					{/* {activeForm !== null && (
            <Button onClick={handleBackClick} icon={<CloseOutlined />}></Button>
          )} */}
				</div>
			</Modal>
		</div>
	);
};

export default ImportCSVModal;
