import { gql } from '@apollo/client';
import { GraphQLConnect } from './reportsApi';

export interface QuartersQueryData {
	quarters: {
		quarter: string;
		year: string;
		percentage: number;
	}[];
}

export interface QuartersQueryVariables {
	year: string;
}

const QUARTERS_QUERY = gql`
	query QUARTERS_QUERY($year: String!) {
		quarters: targetQuarters(where: { year: { equals: $year } }, orderBy: { quarter: asc }) {
			quarter
			year
			percentage
		}
	}
`;

export interface Target {
	id: string;
	year: string;
	target: number;
	exchangeRate: number;
	salesperson: { name: string; id: string };
	agency: { name: string; id: string };
	country: { name: string; id: string };
	currency: string;
}

export interface SalespersonTargetQueryData {
	targets: Target[];
}

export interface SalespersonTargetQueryVariables {
	year?: string;
}

const SALESPERSON_TARGET_QUERY = gql`
	query SALESPERSON_TARGET_QUERY($year: String) {
		targets: salespersonTargets(where: { year: { equals: $year } }) {
			salesperson {
				name
				id
			}
			country {
				name
				id
			}
			agency {
				name
				id
			}
			year
			target
			exchangeRate
			currency
			id
		}
	}
`;

const ALL_SALESPERSON_TARGET_QUERY = gql`
	query SALESPERSON_TARGET_QUERY {
		targets: salespersonTargets(orderBy: { id: asc }) {
			id
			salesperson {
				id
				name
			}
			country {
				id
				name
			}
			agency {
				id
				name
			}
			year
			target
			exchangeRate
			currency
		}
	}
`;

export interface CreateSalespersonTargetData {
	id: string;
}

export interface CreateSalespersonTargetVariables {
	data: {
		salesperson: GraphQLConnect;
		country: GraphQLConnect;
		agency: GraphQLConnect;
		exchangeRate: number;
		currency: string;
		target: number;
		year: string;
	};
}

const CREATE_TARGET_MUTATION = gql`
	mutation CREATE_TARGET_MUTATION($data: SalespersonTargetCreateInput!) {
		createSalespersonTarget(data: $data) {
			id
		}
	}
`;

export interface UpdateSalespersonTargetData {
	id: string;
}

export interface UpdateSalespersonTargetVariables {
	id: string;
	data: {
		salesperson: GraphQLConnect;
		country: GraphQLConnect;
		agency: GraphQLConnect;
		exchangeRate: number;
		currency: string;
		target: number;
		year: string;
	};
}

const UPDATE_TARGET_MUTATION = gql`
	mutation UPDATE_TARGET_MUTATION($id: ID, $data: SalespersonTargetUpdateInput!) {
		updateSalespersonTarget(data: $data, where: { id: $id }) {
			id
		}
	}
`;

export type GetObjectiveSummaryVariables = {
	year: string;
	groupOrder: string[];
};

export type GetObjectiveSummaryData = {
	getObjectiveSummary: {
		items: ObjectiveSummary[];
		totals: Record<string, ObjectiveData>;
	};
};

export type ObjectiveSummary = {
	label: string;
	Q1: ObjectiveData;
	Q2: ObjectiveData;
	Q3: ObjectiveData;
	Q4: ObjectiveData;
	children: {
		label: string;
		Q1: ObjectiveData;
		Q2: ObjectiveData;
		Q3: ObjectiveData;
		Q4: ObjectiveData;
		children: {
			label: string;
			Q1: ObjectiveData;
			Q2: ObjectiveData;
			Q3: ObjectiveData;
			Q4: ObjectiveData;
		}[];
	}[];
};

export type ObjectiveData = {
	investment: number;
	target: number;
	sor: number;
	targetPercentage: number;
};

const GET_OBJECTIVE_SUMMARY = gql`
	query GET_OBJECTIVE_SUMMARY($year: String!, $groupOrder: [String]!) {
		getObjectiveSummary(year: $year, groupOrder: $groupOrder) {
			items {
				label
				rowKey
				Q1
				Q2
				Q3
				Q4
				total
				children
			}
			totals
		}
	}
`;

export {
	QUARTERS_QUERY,
	SALESPERSON_TARGET_QUERY,
	ALL_SALESPERSON_TARGET_QUERY,
	CREATE_TARGET_MUTATION,
	UPDATE_TARGET_MUTATION,
	GET_OBJECTIVE_SUMMARY,
};
