import { gql } from '@apollo/client';
import { Report } from '../interfaces/Report';

export interface ReportsData {
	items: Report[];
	count: number;
}

export type GraphQLConnect = {
	connect: {
		id?: string;
	};
};

export interface CreateReportVariables {
	data: {
		salesperson: GraphQLConnect | null;
		country: GraphQLConnect;
		market: { connect: { id: string }[] } | null;
		agency: GraphQLConnect;
		client: GraphQLConnect;
		campaign: string;
		startDate: string;
		activeDays: number;
		endDate: string;
		product: { connect: { id: string }[] } | null;
		investment: number;
		billing: number;
		budget: number;
		exchangeRate: number;
		currency: string;
		gluCampaignID: string;
		orderNumber?: string;
		billNumber?: string;
		billingMonth: string | null;
		billingDate: string | null;
		billingCompany: GraphQLConnect | null;
		accountManager?: GraphQLConnect;
		planner: GraphQLConnect | null;
		businessModel?: string[];
		fee?: number;
		platforms?: {
			create: {
				name: string;
				startDate: string;
				endDate: string;
				platform: GraphQLConnect | null;
				formats: { connect: { id: string }[] } | null;
				cost: number;
				devices: string[];
				otherVariables: string[];
				buyingType: string;
				rate: number;
				objectiveType: string;
				objectiveValue: number;
				secondaryKPIType: string;
				secondaryKPIValue: number;
			}[];
		};
	};
}

export interface CreateReportData {
	id: string;
}

const CREATE_REPORT_MUTATION = gql`
	mutation CREATE_REPORT_MUTATION($data: ReportCreateInput!) {
		createReport(data: $data) {
			id
		}
	}
`;

export interface CreateOnlyReportsVariables {
	data: {
		salesperson: GraphQLConnect;
		country: GraphQLConnect;
		agency: GraphQLConnect;
		client: GraphQLConnect;
		market: { connect: { id: string }[] };
		billingCompany: GraphQLConnect;
		planner: GraphQLConnect;
		campaign: string;
		product: { connect: { id: string }[] };
		startDate: string;
		endDate: string;
		investment: number;
		billing: number;
		exchangeRate: number;
		currency: string;
		gluCampaignID: string;
		orderNumber?: string;
		billNumber?: string;
		billingDate: string;
	}[];
}

const CREATE_REPORTS_MUTATION = gql`
	mutation CREATE_REPORTS_MUTATION($data: [ReportCreateInput!]!) {
		createReports(data: $data) {
			id
		}
	}
`;

const REPORTS_QUERY = gql`
	query {
		items: reports(orderBy: { gluCampaignID: desc }) {
			id
			salesperson {
				id
				name
			}
			country {
				id
				name
			}
			agency {
				id
				name
			}
			client {
				id
				name
			}
			market {
				id
				name
			}
			planner {
				id
				name
			}
			campaign
			billing
			investment
			objectiveCost
			budget
			exchangeRate
			currency
			gluCampaignID
			startDate
			endDate
			notes {
				document
			}
		}
	}
`;

export interface ReportsQueryVariables {
	take: number;
	skip: number;
	filters?: { AND: any[] };
	sorter?: Record<string, string>;
}

const REPORTS_QUERY_VAR = gql`
	query REPORTS_QUERY_VAR(
		$take: Int!
		$skip: Int!
		$filters: [ReportWhereInput!]!
		$sorter: [ReportOrderByInput!]!
	) {
		items: reports(
			take: $take
			skip: $skip
			where: { OR: $filters }
			orderBy: $sorter
		) {
			id
			agency {
				id
				name
			}
			client {
				id
				name
			}
			campaign
			country {
				id
				name
			}
			market {
				id
				name
			}
			accountManager {
				id
				name
			}
			planner {
				id
				name
			}
			salesperson {
				id
				name
			}
			product {
				id
				name
			}
			billingCompany {
				id
				name
			}
			investment
			objectiveCost
			billing
			budget
			exchangeRate
			campaignDays
			activeDays
			currency
			orderNumber
			billNumber
			billingMonth
			billingDate
			gluCampaignID
			startDate
			endDate
			businessModel
			fee
			platforms(orderBy: { id: desc }) {
				id
				platform {
					id
					name
				}
				formats {
					id
					name
				}
				name
				devices
				otherVariables
				buyingType
				rate
				businessModel
				fee
				objectiveType
				objectiveValue
				objectiveSpend
				objectiveEffectiveCost
				secondaryKPIType
				secondaryKPIValue
				secondaryKPISpend
				secondaryKPIEffectiveCost
				cost
				investment
				budget
				status
				notes {
					document
				}
			}
		}
		count: reportsCount(where: { OR: $filters })
	}
`;

export interface IFinancesQueryVariables {
	startDate: string;
	endDate: string;
	country?: string;
}

const FINANCES_QUERY = gql`
	query FINANCES_QUERY($startDate: CalendarDay!, $endDate: CalendarDay!) {
		items: reports(
			orderBy: { gluCampaignID: desc }
			where: {
				AND: { startDate: { gte: $startDate }, endDate: { lte: $endDate } }
			}
		) {
			id
			salesperson {
				id
				name
			}
			country {
				id
				name
			}
			agency {
				id
				name
			}
			client {
				id
				name
			}
			campaign
			billing
			investment
			budget
			exchangeRate
			billingMonth
			billingDate
			currency
			gluCampaignID
			startDate
			endDate
		}
	}
`;

const CS_REPORTS_QUERY = gql`
	query CS_REPORTS_QUERY(
		$take: Int!
		$skip: Int!
		$filters: [ReportWhereInput!]!
		$sorter: [ReportOrderByInput!]!
	) {
		items: reports(
			take: $take
			skip: $skip
			where: { OR: $filters }
			orderBy: $sorter
		) {
			id
			agency {
				id
				name
			}
			client {
				id
				name
			}
			campaign
			country {
				id
				name
			}
			accountManager {
				id
				name
			}
			gluCampaignID
			startDate
			endDate
			businessModel
			fee
			currency
			platforms(orderBy: { id: desc }) {
				name
				id
				platform {
					name
				}
				formats {
					name
				}
				devices
				otherVariables
				buyingType
				rate
				objectiveType
				objectiveValue
				objectiveSpend
				activeDays
				platformDays
				objectiveEffectiveCost
				secondaryKPIType
				secondaryKPIValue
				secondaryKPISpend
				secondaryKPIEffectiveCost
				cost
				investment
				budget
				notes {
					document
				}
			}
		}
		count: reportsCount(where: { OR: $filters })
	}
`;

const UPDATE_PLATFORM_MUTATION = gql`
	mutation UPDATE_PLATFORM_MUTATION(
		$id: ID!
		$data: ReportPlatformUpdateInput!
	) {
		updateReportPlatform(data: $data, where: { id: $id }) {
			id
		}
	}
`;

const UPDATE_REPORT_PLATFORM_STATUS_MUTATION = gql`
	mutation UPDATE_REPORT_PLATFORM_STATUS_MUTATION($id: ID!, $status: String!) {
		updateReportPlatform(data: { status: $status }, where: { id: $id }) {
			id
			status
		}
	}
`;

export interface UpdateReportMutation {
	report: string;
	data: any;
}

const UPDATE_REPORT_MUTATION = gql`
	mutation UPDATE_REPORT_MUTATION($report: ID!, $data: ReportUpdateInput!) {
		updateReport(where: { id: $report }, data: $data) {
			id
		}
	}
`;

// const UPDATE_REPORT_MUTATION_CSV = gql`
// 	mutation UPDATE_REPORTS_MUTATION(
// 		$where: ReportWhereUniqueInput!
// 		$data: [ReportUpdateArgs!]!
// 	) {
// 		updateReport(where: $where, data: $data) {
// 			# updateReport(data: $data) {
// 			id
// 			campaign
// 		}
// 	}
// `;

const UPDATE_REPORT_MUTATION_CSV = gql`
	mutation UPDATE_REPORT_MUTATION($id: ID!, $data: ReportUpdateInput!) {
		updateReport(where: { id: $id }, data: $data) {
			id
			country
			agency
			startDate
			endDate
			investment
			budget
			currency
			exchangeRate
		}
	}
`;

export interface UpdateReportsMutation {
	id: string;
	data: {
		country?: string;
		agency?: string;
		startDate?: string;
		endDate?: string;
		investment?: number;
		budget?: number;
		currency?: string;
		exchangeRate?: number;
	};
}

export interface ReportsCountData {
	reportsCount: number;
}

export interface ReportsCountVariables {
	gid: string;
}

const GLU_CAMPAIGN_ID_COUNT_QUERY = gql`
	query GLU_CAMPAIGN_ID($gid: String!) {
		reportsCount(where: { gluCampaignID: { contains: $gid } })
	}
`;

export interface StatisticsQueryData {
	totalReports: number;
	badStatusReports: number;
}

const STATISTICS_QUERY = gql`
	query {
		totalReports: reportsCount
	}
`;

export interface ReportPlatformsData {
	report: {
		platforms: {
			name: string;
			id: string;
			platform: {
				name: string;
			};
			formats: {
				name: string;
			}[];
			devices: string[];
			otherVariables: string[];
			buyingType: string;
			rate: number;
			campignDays: number;
			activeDays: number;
			status: string;
			billlingStatus: string;
			objectiveType: string;
			objectiveValue: number;
			objectiveSpend: number;
			platformDays: number;
			objectiveEffectiveCost: number;
			secondaryKPIType: string;
			secondaryKPIValue: number;
			secondaryKPISpend: number;
			ctr: number;
			vtr: number;
			secondaryKPIEffectiveCost: number;
			notes: {
				document: any[];
			};
		}[];
	};
}

export interface ReportPlatformsVariables {
	report: string;
}

const REPORT_PLATFORMS_QUERY = gql`
	query REPORT_PLATFORMS_QUERY($report: ID!) {
		report(where: { id: $report }) {
			platforms(orderBy: { name: asc }) {
				id
				name
				platform {
					id
					name
				}
				formats {
					id
					name
				}
				devices
				otherVariables
				buyingType
				rate
				businessModel
				fee
				objectiveType
				objectiveValue
				objectiveSpend
				platformDays
				activeDays
				objectiveEffectiveCost
				secondaryKPIType
				secondaryKPIValue
				secondaryKPISpend
				secondaryKPIEffectiveCost
				startDate
				endDate
				budget
				cost
				ctr
				vtr
				reporting
				ioOrPo
				finalReport
				screenshots
				reconciliation
				status
				investment
				budget
				notes {
					document
				}
			}
		}
	}
`;

export interface CreateReportPlatformData {
	id: string;
}

export interface CreateReportPlatformVariables {
	id: string;
	data: {
		name: string;
		startDate: string;
		endDate: string;
		platform: GraphQLConnect;
		formats: { connect: { id: string }[] };
		cost: number;
		devices: string[];
		otherVariables: string[];
		buyingType: string;
		rate: number;
		objectiveType: string;
		objectiveValue: number;
		secondaryKPIType: string;
		secondaryKPIValue: number;
	};
}

const CREATE_REPORT_PLATFORM = gql`
	mutation CREATE_REPORT_PLATFORM(
		$id: ID!
		$data: [ReportPlatformCreateInput!]
	) {
		updateReport(where: { id: $id }, data: { platforms: { create: $data } }) {
			id
		}
	}
`;

const REPORT_PLATFORMS_ID_QUERY = gql`
	query REPORT_PLATFORMS_IDS_QUERY($id: ID!) {
		report(where: { id: $id }) {
			platforms {
				id
			}
		}
	}
`;

export interface DeleteReportVariables {
	reports: { id: string }[];
	reportId: string;
}

const DELETE_REPORT_MUTATION = gql`
	mutation DELETE_REPORT_MUTATION(
		$reports: [ReportPlatformWhereUniqueInput!]!
		$reportId: ID!
	) {
		deleteReportPlatforms(where: $reports) {
			id
		}
		deleteReport(where: { id: $reportId }) {
			id
		}
	}
`;

export interface DeletePlatformVariables {
	platformId: string;
}

const DELETE_PLATFORM_MUTATION = gql`
	mutation DELETE_PLATFORM_MUTATION($platformId: ID!) {
		deleteReportPlatform(where: { id: $platformId }) {
			id
		}
	}
`;

export interface SumValuesVariables {
	filters?: { AND: any[] };
}

export interface SumValuesData {
	reports: {
		agency: string;
		client: string;
		country: string;
		campaign: string;
		billing: number;
		investment: number;
		exchangeRate: number;
		budget: number;
		billingMonth: string;
	}[];
}

const SUM_VALUES_QUERY = gql`
	query SUM_VALUES_QUERY($filters: [ReportWhereInput!]!) {
		reports(where: { OR: $filters }) {
			agency {
				id
				name
			}
			client {
				id
				name
			}
			country {
				id
				name
			}
			campaign
			billing
			investment
			exchangeRate
			budget
			billingMonth
		}
	}
`;

export interface ExcelValuesVariables {
	filters?: { AND: any[] };
}

export interface ExcelValuesData {
	reports: {
		gluCampaignID: string;
		agency: { name: string };
		client: { name: string };
		country: { name: string };
		campaign: string;
		billing: number;
		investment: number;
		exchangeRate: number;
		budget: number;
		billingMonth: string;
		currency: string;
		orderNumber: string;
		billNumber: string;
	}[];
}

const EXCEL_VALUES_QUERY = gql`
	query EXCEL_VALUES_QUERY($filters: [ReportWhereInput!]!) {
		reports(where: { OR: $filters }) {
			gluCampaignID
			agency {
				name
			}
			client {
				name
			}
			country {
				name
			}
			campaign
			billing
			investment
			exchangeRate
			budget
			billingMonth
			currency
			orderNumber
			billNumber
		}
	}
`;

const CHECK_GID_EXISTENCE = gql`
	query CheckGIDExistence($gluCampaignID: String!) {
		report(where: { gluCampaignID: $gluCampaignID }) {
			id
		}
	}
`;

export type DuplicateReportVariables = {
	id: string;
	duplicatePlatforms: boolean;
	newName: string;
};

const DUPLICATE_REPORT_MUTATION = gql`
	mutation DUPLICATE_REPORT_MUTATION(
		$id: ID!
		$duplicatePlatforms: Boolean!
		$newName: String
	) {
		duplicateCampaign(
			id: $id
			duplicatePlatforms: $duplicatePlatforms
			newName: $newName
		) {
			id
		}
	}
`;

export type DuplicateReportPlatformVariables = {
	id: string;
	newName: string;
	reportId: string;
};

const DUPLICATE_REPORT_PLATFORM_MUTATION = gql`
	mutation DUPLICATE_REPORT_PLATFORM_MUTATION(
		$id: ID!
		$reportId: ID!
		$newName: String
	) {
		duplicateReportPlatform(id: $id, reportId: $reportId, newName: $newName) {
			id
		}
	}
`;

export {
	REPORTS_QUERY,
	CREATE_REPORT_MUTATION,
	CS_REPORTS_QUERY,
	UPDATE_PLATFORM_MUTATION,
	UPDATE_REPORT_PLATFORM_STATUS_MUTATION,
	GLU_CAMPAIGN_ID_COUNT_QUERY,
	FINANCES_QUERY,
	CREATE_REPORTS_MUTATION,
	REPORTS_QUERY_VAR,
	STATISTICS_QUERY,
	CREATE_REPORT_PLATFORM,
	REPORT_PLATFORMS_QUERY,
	UPDATE_REPORT_MUTATION,
	REPORT_PLATFORMS_ID_QUERY,
	DELETE_REPORT_MUTATION,
	DELETE_PLATFORM_MUTATION,
	SUM_VALUES_QUERY,
	EXCEL_VALUES_QUERY,
	CHECK_GID_EXISTENCE,
	DUPLICATE_REPORT_MUTATION,
	DUPLICATE_REPORT_PLATFORM_MUTATION,
	UPDATE_REPORT_MUTATION_CSV,
};
