import { ReportPlatform } from '@/interfaces/ReportPlatform';
import {
	DUPLICATE_REPORT_PLATFORM_MUTATION,
	DuplicateReportPlatformVariables,
} from '@/lib/reportsApi';
import { useMutation } from '@apollo/client';
import { Divider, Input, Modal } from 'antd';
import { useState } from 'react';

type Props = {
	platform: ReportPlatform;
	reportId: string;
	refetch: any;
	refetchReports: any;
	open: boolean;
	setOpen: (newOpen: string) => void;
};

const DuplicatePlatformModal = ({
	platform,
	refetch,
	open,
	setOpen,
	reportId,
	refetchReports,
}: Props) => {
	const [newName, setNewName] = useState(platform.name);

	const [duplicatePlatformMutation, { loading }] = useMutation<
		ReportPlatform,
		DuplicateReportPlatformVariables
	>(DUPLICATE_REPORT_PLATFORM_MUTATION);

	const handleDuplicate = async () => {
		const data = await duplicatePlatformMutation({
			variables: {
				id: platform.id,
				newName,
				reportId,
			},
		});
		console.log(data);
		refetch();
		console.log(refetchReports);
		await refetchReports();
		setOpen('none');
	};

	return (
		<Modal
			title="Duplicate Campaign"
			open={open}
			onCancel={() => setOpen('none')}
			onOk={handleDuplicate}
			confirmLoading={loading}>
			<p className="my-1">
				Are you sure you want to duplicate this sub campaign?
			</p>
			<Divider className="my-1 bg-gray-200" />
			<p className="mb-1 mt-0 flex gap-x-1">
				<div className="items-center font-semibold">Name:</div>
				<Input
					value={newName}
					onChange={(event) => setNewName(event.target.value)}
					size="small"
				/>
			</p>
			{platform.platform && (
				<p className="mb-1 mt-0">
					<span className="font-semibold">Platform:</span>{' '}
					{platform.platform.name}
				</p>
			)}
			{platform.formats.length > 0 && (
				<p className="mb-1 mt-0">
					<span className="font-semibold">Formats:</span>{' '}
					{platform.formats.map((format) => format.name).join(', ')}
				</p>
			)}
		</Modal>
	);
};

export default DuplicatePlatformModal;
