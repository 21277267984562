import { Report } from '@/interfaces/Report';
import {
	AllSelectsData,
	ALL_SELECTS_QUERY,
	CreateSelectDataMutationVariables,
	CREATE_SELECT_DATA_MUTATION,
} from '@/lib/listsApi';
import {
	GLU_CAMPAIGN_ID_COUNT_QUERY,
	ReportsCountData,
	ReportsCountVariables,
	UpdateReportsMutation,
	UPDATE_REPORT_MUTATION_CSV,
} from '@/lib/reportsApi';
import { capitalize, generateGIDPrefix } from '@/lib/utils';
import { FileOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Popconfirm, Progress, Table, Upload } from 'antd';
import { parse } from 'papaparse';
import { FC, useState } from 'react';
import {
	checkMandatoryFields,
	checkRelationshipFields,
	checkFieldValidation,
	getReportData,
	getDescription,
} from './processUpdateData';
import UploadItem from './UploadItem';

type Props = {
	report?: Report;
	refetch: any;
};

type FileInfoState = {
	read: boolean;
	lines: number;
	fields?: string[];
	fieldsTypes: any[];
};

const UpdateCSVForm: FC<Props> = ({ report, refetch }: Props) => {
	const [fileInfo, setFileInfo] = useState<FileInfoState>({
		read: false,
		lines: 0,
		fieldsTypes: [],
	});
	const [parsedInfo, setParsedInfo] = useState<Record<string, any>[]>([]);
	const [parsedError, setParsedError] = useState<any[]>();

	const [emptyMandatoryFields, setMandatoryFields] = useState({
		state: false,
		data: {},
	});
	const [needsManagerReview, setManagerReview] = useState({
		state: false,
		data: {},
	});
	const [needsFieldReview, setFieldReview] = useState({
		state: false,
		data: {},
	});

	const [progressState, setProgressState] = useState({
		state: '',
		progress: 0,
	});

	const selectQueryData = useQuery<AllSelectsData>(ALL_SELECTS_QUERY);
	const [gidCountQuery] = useLazyQuery<ReportsCountData, ReportsCountVariables>(
		GLU_CAMPAIGN_ID_COUNT_QUERY
	);

	// const [createReportsMutation] = useMutation<any, CreateOnlyReportsVariables>(
	// 	UPDATE_REPORTS_MUTATION
	// );

	const [updateReportMutation] = useMutation<any, UpdateReportsMutation>(
		UPDATE_REPORT_MUTATION_CSV
	);

	const handleFile = async (file: File) => {
		const string = await file.text();
		const parsed = parse<Record<string, any>[]>(string, {
			delimiter: ',',
			header: true,
			dynamicTyping: true,
		});
		const fieldsTypes = Object.entries(parsed.data[0]).map(
			([key, value], index) => ({
				key,
				value: value ? typeof value : 'string',
			})
		);
		setFileInfo({
			read: true,
			lines: parsed.data.length,
			fields: parsed.meta.fields || [],
			fieldsTypes,
		});
		setProgressState({ state: '', progress: 0 });
		setMandatoryFields({ state: false, data: {} });
		setManagerReview({ state: false, data: {} });
		setFieldReview({ state: false, data: {} });
		setParsedInfo(parsed.data);
		if (!selectQueryData.data || parsed.data.length <= 0) return false;
		if (parsed.errors.length > 0) {
			setParsedError(parsed.errors);
		}

		const mandatoryFields = checkMandatoryFields(parsed.data);
		let mandatory =
			Object.entries(mandatoryFields).reduce(
				(acc, curr) => acc + curr[1].length,
				0
			) > 0;

		const neededRelationshipFields = checkRelationshipFields(
			parsed.data,
			selectQueryData.data,
			mandatory
		);
		const needsFieldValidation = checkFieldValidation(parsed.data, mandatory);

		let needed =
			Object.entries(neededRelationshipFields).reduce(
				(acc, curr) => acc + curr[1].length,
				0
			) > 0;
		let reviewFields =
			Object.entries(needsFieldValidation).reduce(
				(acc, curr) => acc + curr[1].length,
				0
			) > 0;

		if (mandatory) {
			setMandatoryFields({ state: true, data: mandatoryFields });
		} else {
			if (needed) {
				setManagerReview({ state: needed, data: neededRelationshipFields });
			}
			if (reviewFields) {
				setFieldReview({
					state: reviewFields,
					data: needsFieldValidation,
				});
			}
		}

		return false;
	};

	// const onConfirmImport = async () => {
	// 	setProgressState({ state: 'ongoing', progress: 0 });
	// 	const gidPrefix = generateGIDPrefix();
	// 	const { data: gidCount } = await gidCountQuery({
	// 		variables: { gid: gidPrefix },
	// 	});

	// 	if (!gidCount) {
	// 		console.error('Error: gidCount es undefined');
	// 		return;
	// 	}

	// 	// console.log('GID Prefix:', gidPrefix);
	// 	// console.log('GID Count:', gidCount);
	// 	// console.log('Parsed Info:', parsedInfo);
	// 	// console.log('Select Query Data:', selectQueryData.data);

	// 	// const reportData = getReportData(
	// 	// 	parsedInfo,
	// 	// 	selectQueryData.data,
	// 	// 	gidPrefix,
	// 	// 	gidCount
	// 	// );

	// 	// Extraer solo los campos `id` de `parsedInfo`
	// 	const reportDataIds = parsedInfo
	// 		.map((item) => item.id)
	// 		.filter((id) => id !== undefined)[0];

	// 	console.log('Report Data IDs:', reportDataIds);

	// 	// const reportData = parsedInfo;

	// 	const reportData = parsedInfo.map((item) => ({
	// 		country: item.country,
	// 		agency: item.agency,
	// 		startDate: item.startDate,
	// 		endDate: item.endDate,
	// 		investment: Number(item.investment), // Asegurar que es número
	// 		budget: Number(item.budget), // Asegurar que es número
	// 		currency: item.currency,
	// 		exchangeRate: Number(item.exchangeRate), // Asegurar que es número
	// 	}));

	// 	// if (!reportData) {
	// 	// 	console.error('Error: reportData es undefined');
	// 	// 	return;
	// 	// }

	// 	// console.log('Report Data:', JSON.stringify(parsedInfo, null, 2));
	// 	// console.log('Report Data:', JSON.stringify(reportData, null, 2));

	// 	// if (!report) {
	// 	// 	console.error('Error: report es undefined');
	// 	// 	return;
	// 	// }

	// 	// const { errors } = await updateReportMutation({
	// 	// 	variables: { data: { ...reportData } },
	// 	// });

	// 	const { errors } = await updateReportMutation({
	// 		variables: {
	// 			id: reportDataIds,
	// 			data: reportData,
	// 		},
	// 	});

	// 	if (errors) {
	// 		console.log(errors);
	// 	}
	// 	await refetch();

	// 	const chunkSize = 100;
	// 	const chunks = [];
	// 	for (let i = 0; i < reportData.length; i += chunkSize) {
	// 		const chunk = reportData.slice(i, i + chunkSize);
	// 		chunks.push(chunk);
	// 	}

	// 	for (let c of chunks) {
	// 		console.log('Chunk Data:', JSON.stringify(c, null, 2));
	// 		setProgressState((prev) => ({
	// 			state: 'ongoing',
	// 			progress: Math.min(prev.progress + 100 / chunks.length, 100),
	// 		}));
	// 	}
	// 	await refetch();
	// 	setProgressState({ state: 'ended', progress: 100 });
	// };

	const onConfirmImport = async () => {
		setProgressState({ state: 'ongoing', progress: 0 });

		const gidPrefix = generateGIDPrefix();
		const { data: gidCount } = await gidCountQuery({
			variables: { gid: gidPrefix },
		});

		if (!gidCount) {
			console.error('Error: gidCount es undefined');
			return;
		}

		const reportData = parsedInfo.map((item) => ({
			id: item.id,
			country: item.country,
			agency: item.agency,
			startDate: item.startDate,
			endDate: item.endDate,
			investment: item.investment,
			budget: item.budget,
			currency: item.currency,
			exchangeRate: item.exchangeRate,
		}));

		if (reportData.length === 1) {
			const { errors } = await updateReportMutation({
				variables: {
					id: reportData[0].id,
					data: { ...reportData[0] },
				},
			});

			if (errors) {
				console.log(errors);
			}

			await refetch();
		} else {
			console.error(
				'Error: Multiple records detected, but mutation expects one.'
			);
		}
	};

	return (
		<div>
			<Upload
				onRemove={() => {
					setFileInfo({ read: false, lines: 0, fields: [], fieldsTypes: [] });
				}}
				accept=".csv"
				multiple={false}
				maxCount={1}
				itemRender={(item, file, fileList, actions) => (
					<UploadItem file={file} actions={actions} />
				)}
				beforeUpload={handleFile}>
				<Button type="text" icon={<FileOutlined />}>
					Select CSV File{' '}
					{/* <small className="ml-2">
            <b>(to update information)</b>
          </small> */}
				</Button>
			</Upload>
			{fileInfo.read && (
				<>
					{parsedError && (
						<div className="my-2">
							<Alert
								type="error"
								message="CSV Parsing Error"
								closable
								description={
									<>
										{parsedError?.map((e) => {
											return (
												<div className="text-sm" key={e.row}>
													Error on row {e.row + 1}: {e.message}
												</div>
											);
										})}
									</>
								}
							/>
						</div>
					)}
					{emptyMandatoryFields.state && (
						<div className="my-2">
							<Alert
								type="error"
								message="Mandatory fields are empty"
								closable
								description={
									<>
										<p>
											Some mandatory fields are missing. To be able to import
											this file, please fill the following fields:{' '}
										</p>
										<ul>
											{Object.entries(emptyMandatoryFields.data).map(
												(item: any) => {
													if (item[1].length > 0) {
														return (
															<li key={item[0]}>
																<span
																	style={{
																		fontWeight: 'bold',
																	}}>{`${capitalize(item[0])}`}</span>
																{` :  ${item[1].map((f: any) => f).join(', ')}`}
															</li>
														);
													}
												}
											)}
										</ul>
									</>
								}
							/>
						</div>
					)}
					{needsManagerReview.state && (
						<div className="my-2">
							<Alert
								type="warning"
								message="Following items can not be uploaded"
								closable
								description={
									<div>
										<p>
											If you want to import this file, please contact your
											manager. Following campaigns don’t exist in GluStudio:
										</p>
										<ul>
											{Object.entries(needsManagerReview.data).map(
												(item: any) => {
													if (item[1].length > 0) {
														return (
															<li key={item[0]}>
																<span
																	style={{
																		fontWeight: 'bold',
																	}}>{`${capitalize(item[0])}`}</span>
																{` :  ${item[1]
																	.map((r: any) => r.name)
																	.join(', ')}`}
															</li>
														);
													}
												}
											)}
										</ul>
									</div>
								}
							/>
						</div>
					)}
					{needsFieldReview.state && (
						<div className="my-2">
							<Alert
								type="info"
								message="Following fields need review before uploading csv"
								closable
								description={
									<div>
										<p>
											If you want to import this file, please review the
											following fields:
										</p>
										<ul>
											{Object.entries(needsFieldReview.data).map(
												(item: any) => {
													if (item[1].length > 0) {
														const description = getDescription(item[0]);
														return (
															<li key={item[0]}>
																<span style={{ fontWeight: 'bold' }}>
																	{`${capitalize(item[0])} (${description}):`}
																	<br />
																</span>
																<ul>
																	{item[1].map((r: any) => (
																		<li key={r.id}> {r.name} </li>
																	))}
																</ul>
															</li>
														);
													}
												}
											)}
										</ul>
									</div>
								}
							/>
						</div>
					)}
					<Alert
						showIcon
						type="success"
						message="File read correctly"
						description={
							<>
								<p>
									{fileInfo.lines} lines were found with{' '}
									{fileInfo.fields?.length} fields:
								</p>
								<Table
									size="small"
									bordered
									className="mb-4"
									scroll={{ y: 100 }}
									pagination={false}
									rowKey="key"
									rowClassName={'text-[12px]'}
									columns={[
										{ title: 'Column', key: 'column', dataIndex: 'key' },
										{
											title: 'Type',
											key: 'type',
											dataIndex: 'value',
											render: (val) => <pre className="mb-0">{val}</pre>,
										},
									]}
									dataSource={fileInfo.fieldsTypes}
								/>
								<p>
									Press the <b>Begin import</b> button to start the import
									process.
								</p>
							</>
						}
					/>
					<Popconfirm
						className="mt-2 w-full"
						placement="top"
						title="Import CSV"
						onConfirm={onConfirmImport}
						disabled={progressState.state === 'ongoing'}
						okText="Yes"
						cancelText="No">
						<Button
							type="primary"
							className="w-full"
							style={{ width: '100%' }}
							loading={progressState.state === 'ongoing'}
							disabled={
								needsManagerReview.state ||
								emptyMandatoryFields.state ||
								needsFieldReview.state
							}>
							Begin import
						</Button>
					</Popconfirm>
					{(progressState.state === 'ongoing' ||
						progressState.state === 'ended') && (
						<div className="mt-2 mr-0 w-full">
							<Progress
								percent={Math.round(progressState.progress)}
								status={progressState.state === 'ended' ? 'success' : 'active'}
							/>

							{parsedInfo.length > 0 && (
								<pre
									style={{
										marginTop: '20px',
										whiteSpace: 'pre-wrap',
										wordBreak: 'break-word',
									}}>
									{JSON.stringify(parsedInfo, null, 2)}
								</pre>
							)}
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default UpdateCSVForm;
