import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { gql } from 'graphql.macro';
import { FC } from 'react';

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
};

interface ProductsData {
  products: { name: string; id: string }[];
}

const PRODUCTS_QUERY = gql`
  query {
    products {
      id
      name
    }
  }
`;

const ProductsSelect: FC<Props> = (props: Props) => {
  const { data, loading } = useQuery<ProductsData>(PRODUCTS_QUERY);

  return (
    <Select
      {...props}
      showSearch
      loading={loading}
      mode="multiple"
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {data?.products.map((product) => (
        <Select.Option key={product.id} value={product.id}>
          {product.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ProductsSelect;
