import { Button, Modal, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
	DeleteReportVariables,
	DELETE_REPORT_MUTATION,
	REPORT_PLATFORMS_ID_QUERY,
} from '@/lib/reportsApi';

type Props = {
	reportId: string;
	refetch: any;
	open: boolean;
	setOpen: (newOpen: string) => void;
};

const DeleteCampaign = ({ reportId, refetch, open, setOpen }: Props) => {
	const { data } = useQuery(REPORT_PLATFORMS_ID_QUERY, { variables: { id: reportId } });
	const [deleteReportMutation, { loading }] = useMutation<any, DeleteReportVariables>(
		DELETE_REPORT_MUTATION
	);

	const onConfirm = async () => {
		await deleteReportMutation({
			variables: { reportId, reports: data.report.platforms.map((p: any) => ({ id: p.id })) },
		});
		await refetch();
		setOpen('none');
	};

	return (
		<Modal
			title="Delete Campaign"
			open={open}
			onOk={onConfirm}
			onCancel={() => setOpen('none')}
			confirmLoading={loading}>
			<p>Are you sure you want to delete this campaign?</p>
		</Modal>
	);
};

export default DeleteCampaign;
