import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { capitalize } from '@/lib/utils';
import dayjs from 'dayjs';

import { Report } from '@/interfaces/Report';

export const itemToCloneData = (clonedItem: Report, gid: string) => {
	return {
		accountManager: { connect: { id: clonedItem.accountManager.id } },
		activeDays: clonedItem.activeDays,
		salesperson: { connect: { id: clonedItem.salesperson.id } },
		country: { connect: { id: clonedItem.country.id } },
		market: { connect: clonedItem.market.map((m) => ({ id: m.id })) },
		agency: { connect: { id: clonedItem.agency.id } },
		client: { connect: { id: clonedItem.client.id } },
		campaign: clonedItem.campaign,
		billingCompany: { connect: { id: clonedItem.billingCompany.id } },
		planner: { connect: { id: clonedItem.planner.id } },
		product: { connect: clonedItem.product.map((p) => ({ id: p.id })) },
		startDate: clonedItem.startDate,
		endDate: clonedItem.endDate,
		investment: clonedItem.investment,
		billing: clonedItem.billing,
		exchangeRate: clonedItem.exchangeRate,
		currency: clonedItem.currency,
		gluCampaignID: gid,
		orderNumber: clonedItem.orderNumber,
		billNumber: clonedItem.billNumber,
		billingDate: clonedItem.billingDate,
		billingMonth: clonedItem.billingMonth,

		// DA ERROR POR UN TEMA DE ID EN PLATFORM
		// platforms: {
		//     create: clonedItem.platforms.map(platform => ({
		//         name: platform.name,
		//         startDate: platform.startDate,
		//         endDate: platform.endDate,
		//         platforms: { connect: platform.platform.id },
		//         formats: {
		//             connect: platform.formats.map((f: any) => ({ id: f.id }))
		//         },
		//         cost: platform.cost,
		//         devices: platform.devices,
		//         otherVariables: platform.otherVariables,
		//         buyingType: platform.buyingType,
		//         rate: platform.buyingType,
		//         objectiveType: platform.objectiveType,
		//         objectiveValue: platform.objectiveValue,
		//         secondaryKPIType: platform.secondaryKPIType,
		//         secondaryKPIValue: platform.secondaryKPIValue
		//     }))
		// }
	};
};

export const checkCampaignStatus = (records: any) => {
	const campaignStatus = getCampaignStatus(records);
	return campaignStatus ? capitalize(campaignStatus) : 'N/A';
};

export const styleCampaignStatus = (records: any) => {
	const campaignStatus = getCampaignStatus(records);
	if (!campaignStatus || campaignStatus === 'N/A') {
		return 'bg-white text-[12px]';
	} else if (campaignStatus === 'active') {
		return 'bg-lime-100 text-[12px]';
	} else if (campaignStatus === 'pending' || campaignStatus === 'implemented') {
		return 'bg-violet-200 text-[12px]';
	} else if (campaignStatus === 'paused') {
		return 'bg-red-200 text-[12px]';
	} else if (campaignStatus === 'finished') {
		return 'bg-gray-200 text-[12px]';
	} else {
		return 'bg-yellow-200 text-[12px]';
	}
};

export const setWarning = (record: any) => {
	const endDate = dayjs(record.endDate);
	const status = checkCampaignStatus(record.platforms);
	if (status === 'N/A') return false;
	if (endDate.isBefore(dayjs()) && status != 'Finished') {
		return true;
	} else {
		return false;
	}
};

const getCampaignStatus = (records: any) => {
	const isActive = records.some(
		(record: { status: string }) => record.status === 'active'
	);
	let status;
	if (isActive) {
		status = 'active';
	} else {
		status = checkStatus(records);
	}
	return status;
};

const checkStatus = (records: any) => {
	if (records.length === 0) {
		return false;
	}
	const str = records[0].status;
	if (records.length > 1) {
		let i = 0;
		if (records[i + 1].status !== str) {
			return 'mixed';
		}
		i++;
	}
	return str;
};

const searchFilter = ({
	setSelectedKeys,
	selectedKeys,
	confirm,
	close,
	visible,
}: FilterDropdownProps) => {
	return (
		<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
			<Input
				value={selectedKeys[0]}
				autoFocus={visible}
				onChange={(e) =>
					setSelectedKeys(e.target.value ? [e.target.value] : [])
				}
				style={{ marginBottom: 8, display: 'block' }}
				onPressEnter={() => {
					confirm({ closeDropdown: true });
				}}
			/>
			<Space align="end">
				<Button
					type="primary"
					icon={<SearchOutlined />}
					onClick={() => {
						confirm({ closeDropdown: true });
					}}
					size="small"
					style={{ width: 90 }}>
					Search
				</Button>
				<Button
					size="small"
					onClick={() => {
						setSelectedKeys([]);
						confirm({ closeDropdown: true });
					}}
					style={{ width: 90 }}>
					Reset
				</Button>
				<Button
					type="link"
					size="small"
					onClick={() => {
						close();
					}}>
					close
				</Button>
			</Space>
		</div>
	);
};
export { searchFilter };
