import { ObjectiveData, ObjectiveSummary } from '@/lib/targetsApi';
import { getFormattedNumber } from '@/lib/utils';
import { formatMoney } from '@/utils/currency';
import { TableColumnsType } from 'antd';

export const getTableColumns = (
	quarters: {
		quarter: string;
		year: string;
		percentage: number;
	}[]
) => {
	const columns: TableColumnsType<ObjectiveSummary> = [
		{
			title: 'Row Label',
			fixed: 'left',
			key: 'country',
			width: 250,
			dataIndex: 'label',
		},
		...quarters.map((q) => ({
			title: `${q.quarter}-${q.year} - ${Math.round(q.percentage * 100)}%`,
			dataIndex: `${q.quarter}`,
			children: [
				{
					title: 'Target',
					key: 'Target',
					dataIndex: 'target',
					align: 'right',
					render: (_: number, record: Record<string, ObjectiveData>) => {
						return formatMoney(record[`${q.quarter}`].target);
					},
				},
				{
					title: 'Investment',
					key: 'investment',
					dataIndex: 'investment',
					align: 'right',
					render: (_: number, record: Record<string, ObjectiveData>) => {
						return formatMoney(record[`${q.quarter}`].investment);
					},
				},
				{
					title: 'SOR',
					key: 'SOR',
					dataIndex: 'sor',
					align: 'center',
					render: (_: number, record: Record<string, ObjectiveData>) => {
						return `${getFormattedNumber(record[`${q.quarter}`].sor * 100, 2)}%`;
					},
				},
				{
					title: '% Target',
					key: 'targetPercentage',
					dataIndex: 'targetPercentage',
					align: 'center',
					render: (_: number, record: Record<string, ObjectiveData>) => {
						return `${getFormattedNumber(record[`${q.quarter}`].targetPercentage * 100, 2)}%`;
					},
				},
			],
		})),
		{
			title: `Total`,
			dataIndex: 'total',
			children: [
				{
					title: 'Target',
					key: 'Target',
					dataIndex: 'target',
					align: 'right',
					render: (_: number, record: Record<string, ObjectiveData>) => {
						return formatMoney(record['total'].target);
					},
				},
				{
					title: 'Investment',
					key: 'investment',
					dataIndex: 'investment',
					align: 'right',
					render: (_: number, record: Record<string, ObjectiveData>) => {
						return formatMoney(record['total'].investment);
					},
				},
				{
					title: 'SOR',
					key: 'SOR',
					dataIndex: 'sor',
					align: 'center',
					render: (_: number, record: Record<string, ObjectiveData>) => {
						return `${getFormattedNumber(record['total'].sor * 100, 2)}%`;
					},
				},
				{
					title: '% Target',
					key: 'targetPercentage',
					dataIndex: 'targetPercentage',
					align: 'center',
					render: (_: number, record: Record<string, ObjectiveData>) => {
						return `${getFormattedNumber(record['total'].targetPercentage * 100, 2)}%`;
					},
				},
			],
		},
	];

	return columns;
};
