import { FC, useContext } from 'react';

import ClientServicesTable from './components/ClientServicesTable';

import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

type Props = {};

const ClientServicesPage: FC<Props> = (props: Props) => {
	const { user } = useContext(AuthContext);

	return (
		<>
			{user?.permission?.clientServicesSeePage === 'yes' ? (
				<ClientServicesTable totalPages={10} />
			) : (
				<Navigate to="/" />
			)}
		</>
	);
};

export default ClientServicesPage;
