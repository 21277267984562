import {
	ReportPlatformAuditData,
	ReportPlatformAuditVariables,
	REPORT_PLATFORM_AUDIT,
} from '@/lib/auditApi';
import { useQuery } from '@apollo/client';
import { Modal, Table, TableColumnsType } from 'antd';
import { FC } from 'react';
import dayjs from 'dayjs';
import { ReportPlatform } from '@/interfaces/ReportPlatform';

type Props = {
	platform: ReportPlatform;
	open: boolean;
	setOpen: (newOpen: string) => void;
	lastModified: string;
};

const AuditModal: FC<Props> = ({ platform, open, setOpen }: Props) => {
	const { loading, data } = useQuery<
		ReportPlatformAuditData,
		ReportPlatformAuditVariables
	>(REPORT_PLATFORM_AUDIT, { variables: { platform: platform.id } });

	const handleOk = () => {
		setOpen('none');
	};

	const handleCancel = () => {
		setOpen('none');
	};

	const columns: TableColumnsType<any> = [
		{
			title: 'User',
			dataIndex: 'user',
			key: 'user',
			render: (user) => user.name,
		},
		{
			title: 'Field',
			dataIndex: 'updatedField',
			key: 'updatedField',
			width: 150,
			ellipsis: true,
		},
		{
			title: 'Old',
			dataIndex: 'oldValue',
			key: 'oldValue',
			width: 80,
			render: (value, record) =>
				record.updatedField === 'notes' ? 'N/A' : value,
		},
		{
			title: 'New',
			dataIndex: 'newValue',
			key: 'newValue',
			width: 80,
			render: (value, record) =>
				record.updatedField === 'notes' ? 'N/A' : value,
		},
		{
			title: 'Last modified',
			dataIndex: 'timestamp',
			key: 'timestamp',
			render: (timestamp) => dayjs(timestamp).format('D MMM YYYY  HH:mm'),
		},
	];

	return (
		<div>
			<Modal
				width={750}
				bodyStyle={{ paddingTop: '20px', maxHeight: 650 }}
				title="Historical changes"
				open={open}
				onOk={handleOk}
				destroyOnClose={true}
				onCancel={handleCancel}
				keyboard
				footer="">
				<Table
					columns={columns}
					dataSource={data?.audits}
					scroll={{ y: 500 }}
					loading={loading}
					bordered
					pagination={false}
					rowClassName={'text-[12px]'}
					size="small"
				/>
			</Modal>
		</div>
	);
};

export default AuditModal;
