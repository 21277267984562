import { FC, useContext } from 'react';
import Home from './components/Home';
import { AuthContext } from './context/AuthContext';
import Login from './routes/Login';
import ErrorRole from './components/ErrorRole';

type Props = {};

const Main: FC<Props> = (props: Props) => {
	const { loading, error, user } = useContext(AuthContext);
	if (loading) {
		return <p>Loading</p>;
	}
	if (error) {
		return <p>Error: {error.message}</p>;
	}
	if (user?.permission?.name === null) {
		return <ErrorRole />;
	}

	if (user?.permission?.name === null) {
		return <ErrorRole />;
	}

	return <div className="h-screen min-h-screen bg-gray-200">{user ? <Home /> : <Login />}</div>;
};

export default Main;
