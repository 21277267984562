const formatMoney = (quantity: number, locale = 'en-US', currency = 'USD') =>
	new Intl.NumberFormat(locale, { style: 'currency', currency }).format(quantity);

const localCurrency = {
	'Puerto Rico': 'USD',
	Mexico: 'MXN',
	Spain: 'EUR',
	Panama: 'USD',
	Miami: 'USD',
	Chile: 'USD',
	Uruguay: 'UYU',
	Colombia: 'USD',
	Brasil: 'BRL',
	Peru: 'PEN',
	Ecuador: 'USD',
	Argentina: 'ARS',
	'República Dominicana': 'DOP',
};

const getLocalCurrency = (country: string) => {
	if (Object.keys(localCurrency).includes(country)) {
		return localCurrency[country as keyof typeof localCurrency];
	} else {
		return 'USD';
	}
};
export { formatMoney, getLocalCurrency };
