import { Report } from '@/interfaces/Report';
import {
	ReportClientAuditData,
	ReportClientAuditVariables,
	REPORT_CLIENT_AUDIT,
} from '@/lib/auditApi';
import { useQuery } from '@apollo/client';
import { Modal, Table, TableColumnsType } from 'antd';
import { FC, useEffect } from 'react';
import dayjs from 'dayjs';

type Props = {
	client: Report;
	open: boolean;
	setOpen: (newOpen: string) => void;
	onLastModifiedChange: (lastModifiedDate: string) => void;
};

const AuditClientModal: FC<Props> = ({
	client,
	open,
	setOpen,
	onLastModifiedChange,
}: Props) => {
	const { loading, data } = useQuery<
		ReportClientAuditData,
		ReportClientAuditVariables
	>(REPORT_CLIENT_AUDIT, { variables: { client: client.id } });

	const handleOk = () => {
		setOpen('none');
	};

	const handleCancel = () => {
		setOpen('none');
	};

	const columns: TableColumnsType<any> = [
		{
			title: 'User',
			dataIndex: 'user',
			key: 'user',
			render: (user) => user.name,
		},
		{
			title: 'Field',
			dataIndex: 'updatedField',
			key: 'updatedField',
			width: 150,
			ellipsis: true,
		},
		{
			title: 'Old',
			dataIndex: 'oldValue',
			key: 'oldValue',
			width: 80,
			render: (value, record) =>
				record.updatedField === 'notes' ? 'N/A' : value,
		},
		{
			title: 'New',
			dataIndex: 'newValue',
			key: 'newValue',
			width: 80,
			render: (value, record) =>
				record.updatedField === 'notes' ? 'N/A' : value,
		},
		{
			title: 'Last modified',
			dataIndex: 'timestamp',
			key: 'timestamp',
			render: (timestamp) => dayjs(timestamp).format('D MMM YYYY  HH:mm'),
		},
	];

	const lastModifiedDate = data?.audits?.length
		? dayjs(data.audits[0].timestamp).format('D MMM YYYY HH:mm')
		: 'N/A';

	useEffect(() => {
		onLastModifiedChange(lastModifiedDate);
	}, [lastModifiedDate, onLastModifiedChange]);

	return (
		<div>
			<Modal
				width={750}
				bodyStyle={{ paddingTop: '20px', maxHeight: 650 }}
				title="Historical changes"
				open={open}
				onOk={handleOk}
				destroyOnClose={true}
				onCancel={handleCancel}
				keyboard
				footer="">
				{/* <div style={{ marginBottom: 20 }}>
					<strong>Last Modified:</strong> {lastModifiedDate}
				</div> */}
				<Table
					columns={columns}
					dataSource={data?.audits}
					scroll={{ y: 500 }}
					loading={loading}
					bordered
					pagination={false}
					rowClassName={'text-[12px]'}
					size="small"
				/>
			</Modal>
		</div>
	);
};

export default AuditClientModal;
