import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { gql } from 'graphql.macro';
import { FC } from 'react';

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
};

interface AgenciesData {
  agencies: { name: string; id: string }[];
}

const AGENCIES_QUERY = gql`
  query {
    agencies {
      id
      name
    }
  }
`;

const AgenciesSelect: FC<Props> = (props: Props) => {
  const { data, loading } = useQuery<AgenciesData>(AGENCIES_QUERY);

  return (
    <Select
      {...props}
      showSearch
      loading={loading}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    >
      {data?.agencies.map((agency) => (
        <Select.Option key={agency.id} value={agency.id}>
          {agency.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AgenciesSelect;
