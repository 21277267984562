import { reportings } from '@/lib/constants';
import { separateCamelCase } from '@/lib/utils';
import { Select } from 'antd';
import { FC } from 'react';

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
};

const ReportingSelect: FC<Props> = (props: Props) => {
  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {reportings.map((reporting) => (
        <Select.Option key={reporting} value={reporting}>
          {separateCamelCase(reporting)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ReportingSelect;
