import { FiltersContext } from '@/context/FiltersContext';
import { Select } from 'antd';
import { FC, useContext } from 'react';

type Props = {
	placeholder: string;
	onChange: (value: string) => void;
};

const ClientSelectField: FC<Props> = (props: Props) => {
	const { clients } = useContext(FiltersContext);

	return (
		<Select
			{...props}
			showSearch
			optionFilterProp="children"
			filterOption={(input, option) =>
				(option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
			}>
			{clients.map((client) => (
				<Select.Option key={client.id} value={client.id}>
					{client.name}
				</Select.Option>
			))}
		</Select>
	);
};

export default ClientSelectField;
