/* eslint-disable */
import React, { useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { formatMoney } from '@/utils/currency';
import { SearchOutlined } from '@ant-design/icons';

const FinancesDataSum: React.FC<{
  data: any;
  queryVariables: any;
  refetch: any;
  setShowMessageStyle: React.Dispatch<
    React.SetStateAction<React.CSSProperties>
  >;
  setShowTableStyle: React.Dispatch<React.SetStateAction<React.CSSProperties>>;
}> = ({
  data,
  queryVariables,
  refetch,
  setShowMessageStyle,
  setShowTableStyle,
}) => {
  const [loading, setLoading] = useState(false);
  const [excelContent, setExcelContent] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const generateSumData = async () => {
    setLoading(true);
    if (data && data.count && data.items) {
      setShowMessageStyle({ display: 'block' });
      setShowTableStyle({ display: 'none' });

      const pageSize = 80;
      const totalPages = Math.ceil(data.count / pageSize);

      let allItems: any[] = [];
      let uniqueAgencies = new Set();
      let uniqueClients = new Set();
      let uniqueCampaigns = new Set();
      let totalBilling = 0;
      let totalInvestment = 0;

      for (let page = 1; page <= totalPages; page++) {
        const response = await refetch({
          ...queryVariables,
          take: pageSize,
          skip: pageSize * (page - 1),
        });
        allItems = allItems.concat(response.data?.items || []);
      }

      allItems.forEach((item) => {
        uniqueAgencies.add(item.agency.name);
        uniqueClients.add(item.client.name);
        uniqueCampaigns.add(item.campaign);
        totalBilling += item.billing * item.exchangeRate;
        totalInvestment += item.investment * item.exchangeRate;
      });

      const summaryData = [
        {
          key: '1',
          agency: `${uniqueAgencies.size}`,
          client: `${uniqueClients.size}`,
          campaign: `${uniqueCampaigns.size}`,
          billing: formatMoney(totalBilling),
          investment: formatMoney(totalInvestment),
        },
      ];

      setExcelContent(summaryData);
      setModalVisible(true);

      setShowMessageStyle({ display: 'none' });
      setShowTableStyle({ display: 'block' });

      setCurrentPage(1);
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    setModalVisible(false);

    setShowMessageStyle({ display: 'none' });
    setShowTableStyle({ display: 'block' });
  };

  const columns = [
    { title: 'Agency', dataIndex: 'agency', key: 'agency' },
    { title: 'Client', dataIndex: 'client', key: 'client' },
    { title: 'Campaign', dataIndex: 'campaign', key: 'campaign' },
    { title: 'Billing USD', dataIndex: 'billing', key: 'billing' },
    { title: 'Investment USD', dataIndex: 'investment', key: 'investment' },
  ];

  return (
    <>
      <Button
        className="mr-1"
        type="primary"
        icon={<SearchOutlined />}
        onClick={generateSumData}
        loading={loading}>
        {loading ? 'Create Summary' : 'View Summary'}
      </Button>
      <Modal
        title="Summary"
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}>
        <Table
          className="raw-data-table"
          dataSource={excelContent}
          bordered
          size="middle"
          columns={columns}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default FinancesDataSum;
