import { TvlApiContext } from '@/context/TvlApiContext';
import { UPDATE_FINANCIAL_ROW } from '@/lib/ReconciliationApi';
import { getFormattedNumber } from '@/lib/utils';
import type { FinancialRow } from '@/routes/ReconciliationPage/ReconciliationPage';
import { useMutation } from '@apollo/client';
import { Button, InputNumber, Modal, Select, type FormInstance } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';

type Props = {
	form: FormInstance;
	spend: number;
	refetch: any;
	rowId: string;
	currency: string;
};

// Formatter y parser definidos fuera del componente
const formatValue = (value: number | string | undefined) =>
	value ? value.toString().replace('.', ',') : '';

const parseValue = (value: string | undefined) =>
	value ? parseFloat(value.replace(',', '.')) : 0;

const CostEditModal: FC<Props> = ({
	spend,
	refetch,
	currency,
	form,
	rowId,
}: Props) => {
	const [updateRowMutation, { loading, data, error }] = useMutation<
		FinancialRow,
		{ rowID: string; data: Partial<FinancialRow> }
	>(UPDATE_FINANCIAL_ROW);

	const { exchangeRates } = useContext(TvlApiContext);
	const [selectedCurrency, setSelectedCurrency] = useState(currency);
	const [cost, setCost] = useState(spend);
	const [exchangedValue, setExchangedValue] = useState(spend);
	const [open, setOpen] = useState(false);

	const handleSave = async () => {
		if (exchangedValue !== undefined && exchangedValue !== cost) {
			await updateRowMutation({
				variables: {
					rowID: rowId,
					data: { cost: exchangedValue },
				},
			});
			await refetch();
			form.setFieldValue(`cost-${rowId}`, exchangedValue);
			setOpen(false);
		}
	};

	useEffect(() => {
		if (selectedCurrency && cost !== undefined) {
			setExchangedValue(
				(cost *
					exchangeRates.find(
						(r) =>
							r.originCurrency === selectedCurrency &&
							r.destinationCurrency === currency
					)!.rate) as number
			);
		}
	}, [cost, selectedCurrency, currency, exchangeRates]);

	return (
		<>
			<Button type="link" size="small" onClick={() => setOpen(true)}>
				Other currency
			</Button>
			<Modal
				open={open}
				onOk={handleSave}
				onCancel={() => setOpen(false)}
				confirmLoading={loading}
				destroyOnClose
				title="Edit cost in other currency">
				<InputNumber
					className="w-full"
					style={{ height: '32px', marginTop: '19px' }}
					disabled={loading}
					size="middle"
					formatter={formatValue}
					parser={parseValue}
					onChange={(value) => setCost(value || 0)}
					value={cost}
					addonAfter={
						<Select
							className="w-20"
							value={selectedCurrency}
							onChange={setSelectedCurrency}
							options={exchangeRates
								.filter((r) => r.destinationCurrency === currency)
								.map((r) => ({
									value: r.originCurrency,
									label: r.originCurrency,
								}))}
						/>
					}
				/>
				<p className="mt-1 mb-1 text-xs text-gray-500">
					Note: If you're not seeing the desired currency, it's because there is
					not exchange rate for it. Please contact the financial team.
				</p>
				<p>
					The value that will be saved is{' '}
					{getFormattedNumber(exchangedValue, 2)} {currency}
				</p>
			</Modal>
		</>
	);
};

export default CostEditModal;
