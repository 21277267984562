import { AuthContext } from '@/context/AuthContext';
import { FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
	permissions: string[];
	children: React.ReactNode;
};

const RolesAuthRoute: FC<Props> = (props: Props) => {
	const { user } = useContext(AuthContext);

	const canAccess =
		user &&
		props.permissions?.some((permission) => {
			if (user.permission && typeof user.permission === 'string') {
				return user.permission === permission;
			}
			return true;
		});

	return canAccess ? <>{props.children}</> : <Navigate to="/" />;
};

export default RolesAuthRoute;
