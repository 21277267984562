import { createContext, FC, ReactNode } from 'react';

interface IAppStateContext {}

const AppStateContext = createContext<IAppStateContext>({});

interface IAppStateProviderProps {
  children?: ReactNode;
}

const AppStateProvider: FC<IAppStateProviderProps> = ({
  children,
}: IAppStateProviderProps) => {
  return (
    <AppStateContext.Provider value={{}}>{children}</AppStateContext.Provider>
  );
};

export { AppStateProvider, AppStateContext };
