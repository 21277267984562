import { ApolloError, useMutation, useQuery } from '@apollo/client';
import React, { createContext, FC, ReactNode } from 'react';
import { User } from '../interfaces/User';
import {
	AuthenticatedUserData,
	AUTHENTICATED_USER,
	AuthenticateUserWithPasswordData,
	AuthenticateUserWithPasswordVariables,
	AUTHENTICATE_USER_WITH_PASSWORD,
	END_SESSION,
} from '../lib/authApi';

interface IAuthContext {
	loading: boolean;
	error?: ApolloError;
	authLoading: boolean;
	authError?: ApolloError;
	user?: User;
	authResult?: string;
	handleLogin: (email: string, password: string) => void;
	handleLogout: () => void;
}

const AuthContext = createContext<IAuthContext>({
	loading: false,
	authLoading: false,
	handleLogin: () => {},
	handleLogout: () => {},
});

interface IAuthStateProviderProps {
	children?: ReactNode;
}

const AuthStateProvider: FC<IAuthStateProviderProps> = ({ children }: IAuthStateProviderProps) => {
	const { loading, error, data } = useQuery<AuthenticatedUserData>(AUTHENTICATED_USER);

	const [authenticateUser, authenticateUserData] = useMutation<
		AuthenticateUserWithPasswordData,
		AuthenticateUserWithPasswordVariables
	>(AUTHENTICATE_USER_WITH_PASSWORD);

	const [endSession] = useMutation(END_SESSION);

	const handleLogin = (email: string, password: string) => {
		authenticateUser({
			variables: { email, password },
			refetchQueries: [{ query: AUTHENTICATED_USER }],
		});
	};

	const handleLogout = () => {
		endSession({ refetchQueries: [{ query: AUTHENTICATED_USER }] });
	};

	return (
		<AuthContext.Provider
			value={{
				user: data?.authenticatedItem,
				loading,
				error,
				authLoading: authenticateUserData.loading,
				authError: authenticateUserData.error,
				authResult: authenticateUserData.data?.authenticateUserWithPassword.__typename,
				handleLogin,
				handleLogout,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthStateProvider, AuthContext };
