import { Alert, Button, Form, Input } from 'antd';
import { FC, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { AuthenticateUserWithPasswordVariables } from '../lib/authApi';

import { ReactComponent as Logo } from '../assets/logo-light.svg';

type Props = {};

const Login: FC<Props> = (props: Props) => {
  const { handleLogin, authLoading, authResult } = useContext(AuthContext);

  const onFinish = (values: AuthenticateUserWithPasswordVariables) => {
    handleLogin(values.email, values.password);
  };
  const onFinishFailed = () => {};

  return (
    <div className="flex h-full items-center justify-center">
      <div className="w-3/12 rounded-sm bg-white px-4 pt-6 shadow">
        <Logo className="mx-auto mb-4 block w-48" />
        <Form
          name="login"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please input your email' },
              { type: 'email', message: 'Must be a valid email' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password' }]}
          >
            <Input.Password />
          </Form.Item>
          {authResult === 'UserAuthenticationWithPasswordFailure' && (
            <div className="mb-2">
              <Alert message="Authentication failed. Please try again." type="error" showIcon />
            </div>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={authLoading}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
