import { status } from '@/lib/constants';
import { capitalize } from '@/lib/utils';
import { Select } from 'antd';
import { FC } from 'react';

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
};

const StatusSelect: FC<Props> = (props: Props) => {
  return (
    <Select
      {...props}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {status.map((status) => (
        <Select.Option key={status.status} value={status.status}>
          {capitalize(status.status)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default StatusSelect;
