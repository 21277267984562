import { GET_FINANCIAL_ROWS } from '@/lib/ReconciliationApi';
import ReconciliationTable from '@/routes/ReconciliationPage/ReconciliationTable';
import { SearchOutlined } from '@ant-design/icons';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Col, DatePicker, Form, Row, Select } from 'antd';

import { useContext } from 'react';

import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import SubmitButton from '@/components/SubmitButton';

export type FinancialRow = {
	id: string;
	platformId: string;
	reportId: string;
	agency: string;
	currency: string;
	exchangeRate: number;
	client: string;
	ioOrPo: string;
	campaign: string;
	product: string[];
	formats: string[];
	businessModel: string[];
	buyingType: string;
	rate: number;
	orderNumber: string;
	onTvl: boolean;
	investment: number;
	monthlyBilling: number;
	Platform: string;
	cost: number;
	comment: string;
	startDate: string;
	endDate: string;
	platform: string;
	platformName: string;
};

const CONCILIATION_FILTER_QUERY = gql`
	query {
		accountManagers {
			id
			name
		}
		platforms {
			id
			name
		}
		clients {
			id
			name
		}
		agencies {
			id
			name
		}
	}
`;

const ReconciliationPage = () => {
	const [form] = Form.useForm();

	const [getFinancialRows, { loading, error, data }] = useLazyQuery<{
		getFinancialRows: FinancialRow[];
	}>(GET_FINANCIAL_ROWS, { fetchPolicy: 'network-only' });

	const { data: filters, loading: filtersLoading } = useQuery<{
		accountManagers: { id: string; name: string }[];
		clients: { id: string; name: string }[];
		agencies: { id: string; name: string }[];
		platforms: { id: string; name: string }[];
	}>(CONCILIATION_FILTER_QUERY);

	const refetch = async () => {
		const { month, accountManagers, clients, agencies, platforms, formats } =
			form.getFieldsValue();

		getFinancialRows({
			variables: {
				month: month && month.startOf('month').format('YYYY-MM-DD'),
				...(clients && { clients: clients }),
				...(agencies && { agencies: agencies }),
				...(platforms && { platforms: platforms }),
				...(formats && { formats: formats }),
				...(accountManagers && {
					accountManagers: accountManagers,
				}),
			},
		});
	};

	const handleSubmit = () => {
		const { month, accountManagers, clients, agencies, platforms, formats } =
			form.getFieldsValue();

		if (month) {
			getFinancialRows({
				variables: {
					month: month && month.startOf('month').format('YYYY-MM-DD'),
					...(clients && { clients: clients }),
					...(agencies && { agencies: agencies }),
					...(platforms && { platforms: platforms }),
					...(formats && { formats: formats }),
					...(accountManagers && {
						accountManagers: accountManagers,
					}),
				},
			});
		}
	};

	const { user } = useContext(AuthContext);

	return (
		<div>
			{user?.permission?.reconciliationDataSeePage === 'yes' ? (
				<>
					<h3 className="mb-1 mt-0 text-xl font-medium">Reconciliation page</h3>
					<p className="mb-6 mt-0 text-sm font-light">
						Select month and account manager to get financial rows
					</p>
					<Form
						layout="horizontal"
						form={form}
						name="validateOnly"
						onFinish={handleSubmit}>
						<div className="mb-4 rounded bg-white px-4 py-4 shadow">
							<Row gutter={32} className="mt-2">
								<Col span={6}>
									<Form.Item
										className="my-0"
										label="Month"
										name="month"
										rules={[
											{
												required: true,
												type: 'date',
												message: 'Please select a month',
											},
										]}>
										<DatePicker
											className="w-full"
											picker="month"
											placeholder="Select month"
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										className="my-0"
										label="Account manager"
										name="accountManagers">
										<Select
											mode="multiple"
											showSearch
											optionFilterProp="label"
											className="w-full"
											placeholder="Select account manager"
											loading={filtersLoading}
											options={filters?.accountManagers.map(({ id, name }) => ({
												value: id,
												label: name,
											}))}
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item className="my-0" label="Client" name="clients">
										<Select
											mode="multiple"
											showSearch
											optionFilterProp="label"
											className="w-full"
											placeholder="Select client"
											loading={filtersLoading}
											options={filters?.clients.map(({ id, name }) => ({
												value: id,
												label: name,
											}))}
										/>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item className="my-0" label="Platform" name="platforms">
										<Select
											mode="multiple"
											showSearch
											optionFilterProp="label"
											className="w-full"
											placeholder="Select platform"
											loading={filtersLoading}
											options={filters?.platforms.map(({ id, name }) => ({
												value: id,
												label: name,
											}))}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={32} className="mt-4">
								<Col span={6}>
									<Form.Item className="my-0" label="Agency" name="agencies">
										<Select
											mode="multiple"
											showSearch
											optionFilterProp="label"
											className="w-full"
											placeholder="Select agencies"
											loading={filtersLoading}
											options={filters?.agencies.map(({ id, name }) => ({
												value: id,
												label: name,
											}))}
										/>
									</Form.Item>
								</Col>
							</Row>
							<div className="flex justify-end">
								<SubmitButton
									form={form}
									loading={loading}
									icon={<SearchOutlined />}>
									Search sub campaigns
								</SubmitButton>
							</div>
						</div>
					</Form>
					{loading && data?.getFinancialRows.length === 0 && <p>Loading...</p>}
					{error && <p>{error.message}</p>}
					{data && (
						<ReconciliationTable
							rows={data.getFinancialRows}
							refetch={refetch}
							loading={loading}
						/>
					)}
				</>
			) : (
				<Navigate to="/" />
			)}
		</div>
	);
};

export default ReconciliationPage;
