import { Table, TableColumnsType } from 'antd';
import { MediaAnalysisRow } from './MediaAnalysisPage';
import { formatMoney } from '@/utils/currency';
import { getFormattedNumber } from '@/lib/utils';

type Props = {
	rows: MediaAnalysisRow[];
	loading: boolean;
};

const MediaAnalysisTable = ({ rows }: Props) => {
	const tableColumns: TableColumnsType<MediaAnalysisRow> = [
		{
			title: 'Subcampaign',
			dataIndex: 'subcampaign',
			key: 'subcampaign',
			fixed: true,
			width: 250,
		},
		{
			title: 'Campaign',
			dataIndex: 'campaign',
			key: 'campaign',
			fixed: true,
		},
		{
			title: 'Account Manager',
			dataIndex: 'accountManager',
			key: 'accountManager',
			render: (value) => value || 'N/A',
		},
		{
			title: 'Agency',
			dataIndex: 'agency',
			key: 'agency',
		},
		{
			title: 'Client',
			dataIndex: 'client',
			key: 'client',
		},
		{
			title: 'Market',
			dataIndex: 'market',
			key: 'market',
			render: (market) => market.join(', '),
		},
		{
			title: 'Platform',
			dataIndex: 'platform',
			key: 'platform',
		},
		{
			title: 'Formats',
			dataIndex: 'formats',
			key: 'formats',
			render: (formats) => formats.join(', '),
		},
		{
			title: 'Business Model',
			dataIndex: 'businessModel',
			key: 'businessModel',
			render: (businessModels) => businessModels.join(', '),
		},
		{
			title: 'Buying Type',
			dataIndex: 'buyingType',
			key: 'buyingType',
		},
		{
			title: 'Rate',
			dataIndex: 'rate',
			key: 'rate',
			render: (rate) => (rate ? `$${rate}` : 'N/A'),
		},
		{
			title: 'Effective Cost',
			dataIndex: 'secondaryKPIEffectiveCost',
			key: 'secondaryKPIEffectiveCost',
			render: (effectiveCost) =>
				effectiveCost !== undefined ? `$${effectiveCost}` : 'N/A',
		},
		{
			title: 'Spend',
			dataIndex: 'objectiveSpend',
			key: 'objectiveSpend',
			render: (objectiveSpend) =>
				objectiveSpend ? `$${objectiveSpend}` : 'N/A',
		},
		{
			title: 'Cost',
			dataIndex: 'cost',
			key: 'cost',
			render: (value, { currency }) =>
				value !== undefined ? formatMoney(value, 'en-US', currency) : 'N/A',
		},
		{
			title: 'Investment',
			dataIndex: 'investment',
			key: 'investment',
			render: (value, { currency }) =>
				value ? formatMoney(value, 'en-US', currency) : 'N/A',
		},
		{
			title: 'Profit',
			dataIndex: 'investment',
			key: 'profit',
			render: (
				value,
				{ investment, exchangeRate, cost, incentive, currency }
			) => {
				if (cost !== undefined && incentive !== undefined) {
					return formatMoney(
						(1 - incentive / 100) * investment - cost,
						'en-US',
						currency
					);
				} else {
					return 'N/A';
				}
			},
		},
		{
			title: 'Margin %',
			dataIndex: 'rate',
			key: 'margin',
			render: (_, { rate, objectiveEffectiveCost }) => {
				if (rate !== null && objectiveEffectiveCost !== undefined) {
					return `${getFormattedNumber(
						(rate - objectiveEffectiveCost) / rate
					)}%`;
				} else {
					return 'N/A';
				}
			},
		},
		{
			title: 'Profit %',
			dataIndex: 'investment',
			key: 'profitPercent',
			render: (_, { investment, cost, incentive }) => {
				if (cost !== undefined && incentive !== undefined) {
					return `${getFormattedNumber(
						(((1 - incentive / 100) * investment - cost) / investment) * 100
					)}%`;
				} else {
					return 'N/A';
				}
			},
		},
	];

	return (
		<>
			<p className="my-3 text-xl font-medium">Results ({rows.length})</p>
			<div className="mt-4 rounded-lg border border-gray-200 bg-white p-2 shadow">
				<Table
					size="small"
					virtual
					bordered
					pagination={false}
					scroll={{ x: 2500, y: 500 }}
					columns={tableColumns}
					rowKey={(record) => record.id}
					dataSource={rows}></Table>
			</div>
		</>
	);
};

export default MediaAnalysisTable;
