/* eslint-disable */
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { generateGIDPrefix } from '@/lib/utils';
import {
	Button,
	DatePicker,
	Form,
	Table,
	TableColumnsType,
	Space,
	Tooltip,
	Menu,
	Dropdown,
	Popconfirm,
	Popover,
} from 'antd';
import {
	FC,
	useContext,
	useState,
	useEffect,
	useCallback,
	useMemo,
} from 'react';
import { Report } from '@/interfaces/Report';
import {
	ReportsData,
	ReportsQueryVariables,
	REPORTS_QUERY_VAR,
	CREATE_REPORT_MUTATION,
	CHECK_GID_EXISTENCE,
} from '@/lib/reportsApi';
import { formatMoney } from '@/utils/currency';
import { FiltersContext } from '@/context/FiltersContext';
import {
	DeleteOutlined,
	SearchOutlined,
	CopyOutlined,
} from '@ant-design/icons';
import EditCampaignModal from './EditCampaignModal';
import { searchFilter, itemToCloneData } from './utils';
import { AuthContext } from '../../context/AuthContext';
import ReportExcelData from './FinancesExcelData';
import FinancesDataSum from './FinancesDataSum';
import dayjs from 'dayjs';
import './style.css';
import DuplicateCampaignModal from '@/routes/FinancesDataPage/DuplicateCampaignModal';
import CampaignActions from '@/routes/FinancesDataPage/CampaignActions';

// import SummaryModal from './GetSummary';

type Props = {
	totalPages: number;
};

const relationshipKeys = ['agency', 'client'];

const FinancesDataTable: FC<Props> = (props: Props) => {
	const { user } = useContext(AuthContext);
	const { agencies, clients } = useContext(FiltersContext);

	const [showMessageStyle, setShowMessageStyle] = useState<React.CSSProperties>(
		{
			display: 'none',
		}
	);
	const [showTableStyle, setShowTableStyle] = useState<React.CSSProperties>({
		display: 'block',
	});

	const [queryVariables, setQueryVariables] = useState<any>({
		take: 15,
		skip: 0,
		filters: { AND: [[], [], [], [], []] },
		// sorter: [ { startDate: 'desc' }, { gluCampaignID: 'desc' } ],
		sorter: [{ gluCampaignID: 'desc' }],
	});
	const [dateFilter, setDateFilter] = useState<any>();
	const { loading, data, refetch } = useQuery<
		ReportsData,
		ReportsQueryVariables
	>(REPORTS_QUERY_VAR, {
		variables: queryVariables,
		fetchPolicy: 'network-only',
	});

	const [pageSize, setPageSize] = useState<number>(15);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [clonedItems, setClonedItems] = useState<Report[]>([]);
	const [createReport] = useMutation(CREATE_REPORT_MUTATION);
	const [checkGIDExistence] = useLazyQuery(CHECK_GID_EXISTENCE);

	const existingIDs = new Set();

	const generateGID = () => {
		const prefix = generateGIDPrefix();
		let newId = `${prefix}`;

		if (existingIDs.has(newId)) {
			let suffix = 0;
			while (existingIDs.has(`${prefix}.${suffix}`)) {
				suffix++;
			}
			newId = `${prefix}.${suffix}`;
		}

		existingIDs.add(newId);
		return newId;
	};

	const handleClone = (record: any) => {};

	const handleDateChange = useCallback(
		(values: any) => {
			setDateFilter({
				start: values[0].format('YYYY-MM-DD'),
				end: values[1].format('YYYY-MM-DD'),
			});
			const newVars = { ...queryVariables };
			const filters = newVars.filters.AND;
			filters[4] = {
				AND: [
					{ startDate: { gte: values[0].format('YYYY-MM-DD') } },
					{ startDate: { lte: values[1].format('YYYY-MM-DD') } },
				],
			};
			newVars.filters.AND = filters;
			setQueryVariables(newVars);
			refetch();
		},
		[queryVariables, refetch]
	);

	const handleOnChange = useCallback(
		(pagination: any, filters: any, sorter: any) => {
			setPageSize(pagination.pageSize);
			const f = Object.entries(filters).map((filter: any) => {
				if (!filter[1]) return [];
				const key = filter[0];
				return {
					OR: filter[1].map((v: string) => {
						if (relationshipKeys.includes(key)) {
							return { [key]: { name: { equals: v } } };
						} else {
							return { [key]: { contains: v, mode: 'insensitive' } };
						}
					}),
				};
			});
			if (dateFilter) {
				f.push({
					OR: [
						{
							AND: [
								{ startDate: { gte: dateFilter.start } },
								{ startDate: { lte: dateFilter.end } },
							],
						},
					],
				});
			}
			setQueryVariables({
				take: pagination.pageSize,
				skip: pagination.pageSize * (pagination.current - 1),
				filters: { AND: f },
				sorter: sorter.order
					? { [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc' }
					: [{ startDate: 'desc' }, { gluCampaignID: 'desc' }],
			});
		},
		[dateFilter]
	);

	const handleReset = useCallback(() => {
		// const f = queryVariables;
		// f.filters.AND[4] = [];
		// setQueryVariables({ ...f });

		setQueryVariables({
			take: 15,
			skip: 0,
			filters: { AND: [[], [], [], [], []] },
			sorter: [{ gluCampaignID: 'desc' }],
		});

		setDateFilter(undefined);
		setCurrentPage(1);
		setTimeout((): void => {
			refetch();
		}, 300);
	}, [refetch()]);

	const tableColumns: TableColumnsType<Report> = [
		{
			key: 'gluCampaignID',
			dataIndex: 'gluCampaignID',
			title: 'Campaign ID',
			filterSearch: true,
			filterIcon: <SearchOutlined />,
			filterDropdown: searchFilter,
			width: '180',
		},
		{
			key: 'agency',
			dataIndex: 'agency',
			title: 'Agency',
			filters: [...agencies]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((c) => ({
					text: c.name,
					value: c.name,
				})),
			render: (text) => text.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'client',
			dataIndex: 'client',
			title: 'Client',
			filters: [...clients]
				.sort((a, b) => a.name.localeCompare(b.name))
				.map((c) => ({
					text: c.name,
					value: c.name,
				})),
			render: (text) => text.name,
			filterSearch: true,
			ellipsis: true,
		},
		{
			key: 'campaign',
			dataIndex: 'campaign',
			title: 'Campaign',
			ellipsis: true,
			filterIcon: <SearchOutlined />,
			filterDropdown: searchFilter,
		},
		{
			key: 'billingLC',
			dataIndex: 'billing',
			title: 'Billing LC',
			render: (billing, record) =>
				formatMoney(billing, 'en-US', record.currency),
			sortDirections: ['ascend', 'descend'],
			sorter: true,
		},
		{
			key: 'billingUSD',
			dataIndex: 'billing',
			title: 'Billing USD',
			render: (billing, record) => formatMoney(billing * record.exchangeRate),
		},
		{
			key: 'investmentLC',
			dataIndex: 'investment',
			title: 'Investment LC',
			render: (investment, record) =>
				formatMoney(investment, 'en-US', record.currency),
			sortDirections: ['ascend', 'descend'],
			width: 130,
			sorter: true,
		},
		{
			key: 'investmentUSD',
			dataIndex: 'investment',
			title: 'Investment USD',
			render: (investment, record) =>
				formatMoney(investment * record.exchangeRate),
			ellipsis: true,
		},
		{
			key: 'orderNumber',
			dataIndex: 'orderNumber',
			title: 'Order #',
			filterIcon: <SearchOutlined />,
			filterDropdown: searchFilter,
		},
		{
			key: 'billNumber',
			dataIndex: 'billNumber',
			title: 'Bill #',
			ellipsis: true,
		},
		{
			key: 'billingMonth',
			dataIndex: 'billingMonth',
			width: 160,
			title: 'Order Month',
			ellipsis: true,
			sortDirections: ['ascend', 'descend'],
			sorter: true,
			render: (text) => {
				if (!text) return 'N/A';
				const dates = text.split(',');
				const formattedDates = dates.map((value: any) => {
					let date = dayjs(value.trim());
					if (date.year() === 2001) {
						date = date.year(2024);
					}
					return date.format('MMM-YYYY');
				});

				const firstDate = formattedDates[0];
				const lastDate = formattedDates[formattedDates.length - 1];

				return firstDate === lastDate ? firstDate : `${firstDate}, ${lastDate}`;
			},
		},
		{
			key: 'actions',
			dataIndex: 'gluCampaignID',
			title: 'Actions',
			width: 100,
			align: 'center',
			render: (value, record) => {
				return (
					<>
						<Space>
							<CampaignActions report={record} refetch={refetch} />
						</Space>
					</>
				);
			},
		},
	];

	const totalPages = Math.ceil((data?.count || 0) / pageSize);

	// useEffect(() => {
	//   setShowTable(true);
	// }, []);

	const combinedData = [
		...clonedItems.map((item) => ({ ...item, gluCampaignID: item.id })),
		...(data?.items || []),
	];

	const getRowClassName = (record: Report, index: number) => {
		const isCloned = clonedItems.some((item) => item.id === record.id);
		const clonedClass = isCloned ? 'cloned-row' : '';
		return index % 2 === 0
			? `even-row text-[12px] ${clonedClass}`
			: `odd-row text-[12px] ${clonedClass}`;
	};

	const clearDatePicker = () => {
		setDateFilter(undefined);
	};

	const [isHovered, setIsHovered] = useState(false);

	return (
		<div>
			<div className="flex" style={{ justifyContent: 'space-between' }}>
				<div className="flex">
					<Form.Item label="Date range" className="mb-2">
						<DatePicker.RangePicker
							onChange={handleDateChange}
							value={
								dateFilter === undefined
									? dateFilter
									: [dayjs(dateFilter.start), dayjs(dateFilter.end)]
							}
						/>
					</Form.Item>
					<Button
						icon={<DeleteOutlined />}
						ghost
						className="ml-1"
						onClick={handleReset}
						style={{
							backgroundColor: 'transparent',
							borderColor: 'transparent',
							color: isHovered ? '#3c3c3c' : '#4a4a4a',
						}}
					/>
				</div>
				<div>
					{/* <SummaryModal /> */}
					<FinancesDataSum
						data={data}
						queryVariables={queryVariables}
						refetch={refetch}
						setShowMessageStyle={setShowMessageStyle}
						setShowTableStyle={setShowTableStyle}
						// resetPage={() => {
						//   setCurrentPage(1);
						//   clearDatePicker();
						// }}
					/>
					<ReportExcelData
						data={data}
						queryVariables={queryVariables}
						refetch={refetch}
						setShowMessageStyle={setShowMessageStyle}
						setShowTableStyle={setShowTableStyle}
						// resetPage={() => {
						//   setCurrentPage(1);
						//   clearDatePicker();
						// }}
					/>
				</div>
			</div>

			<div style={showMessageStyle}>
				<div className="mb-4 mt-4 rounded-lg bg-gray-400 px-4 py-8 pb-32 pt-32 text-left font-bold text-white">
					<div className="flex items-center justify-center">
						<div className="mr-4 flex-shrink-0">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="mr-2 h-16 w-16 animate-bounce"
								viewBox="0 0 16 16">
								<path
									fill="none"
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="1.5"
									d="M10.75 11.25c4.5 0 4.5-5.5 0-5.5h-9v5c0 5 8.5 5 8.5 0v-5m-1.5-4v1.5m-3-1.5v1.5m-3-1.5v1.5"
								/>
							</svg>
							<span className="sr-only">Loading...</span>
						</div>
						<div>
							{user?.name && (
								<h1 className="mb-2 text-4xl">
									Hi {user.name.split(' ')[0]}! Take a coffee break while
									<br></br> we process the request for the data.
								</h1>
							)}
							<h3 className="text-1xl mb-0 mt-6">
								We are currently processing a total of
								<span className="ml-2 mr-2 rounded-lg bg-red-500 p-1 text-base font-bold leading-4 text-white opacity-100">
									{totalPages * 10}
								</span>
								records.
							</h3>
							<h3 className="text-1xl mb-12 mt-1">
								This process may take some time.
							</h3>
						</div>
					</div>
				</div>
			</div>

			<div style={showTableStyle}>
				<Table
					columns={tableColumns}
					dataSource={combinedData}
					rowClassName={getRowClassName}
					rowKey="id"
					loading={loading && !data?.items}
					className="raw-data-table"
					bordered
					size="middle"
					pagination={{
						current: currentPage,
						pageSize,
						total: data?.count,
						position: ['bottomRight'],
						pageSizeOptions: [10, 15, 20, 30],
						onChange: (page) => setCurrentPage(page),
					}}
					onChange={handleOnChange}
				/>
			</div>
		</div>
	);
};

export default FinancesDataTable;
