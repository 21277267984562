import {
	QuartersQueryData,
	QuartersQueryVariables,
	QUARTERS_QUERY,
	GET_OBJECTIVE_SUMMARY,
	GetObjectiveSummaryData,
	GetObjectiveSummaryVariables,
} from '@/lib/targetsApi';
import { useQuery } from '@apollo/client';
import { DatePicker, Select, Skeleton, Space, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { FC, useState, useContext } from 'react';

import './style.css';
import TargetTableSummary from '@/routes/TargetPage/TargetTableSummary';
import { getTableColumns } from '@/routes/TargetPage/utils';

import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

type Props = {};

const TargetPage: FC<Props> = (props: Props) => {
	const [targetYear, setTargetYear] = useState(dayjs().year().toString());
	const [selectedItem, setSelectedItem] = useState<string>('country');

	const { user } = useContext(AuthContext);

	const quartersQueryResult = useQuery<
		QuartersQueryData,
		QuartersQueryVariables
	>(QUARTERS_QUERY, {
		variables: {
			year: targetYear,
		},
	});

	const { data: objectiveSummary } = useQuery<
		GetObjectiveSummaryData,
		GetObjectiveSummaryVariables
	>(GET_OBJECTIVE_SUMMARY, {
		variables: {
			year: targetYear,
			groupOrder:
				selectedItem === 'country'
					? ['country', 'agency', 'salesperson']
					: ['salesperson', 'agency', 'country'],
		},
	});

	if (!quartersQueryResult || !objectiveSummary) {
		return (
			<>
				<Skeleton active />
				<Skeleton active />
				<Skeleton active />
			</>
		);
	}

	const items = [
		{ value: 'country', label: 'Country' },
		{ value: 'salesperson', label: 'Sales Person' },
	];

	const handleSelect = (value: any) => {
		setSelectedItem(value);
	};

	const handleDateChange = (date: any) => {
		const selectedYear = date ? `${date.year()}` : dayjs().year().toString();
		setTargetYear(selectedYear);
	};

	return (
		<div>
			{user?.permission?.objetivesSeePage === 'yes' ? (
				<>
					<div className="my-2 flex">
						<Space size="middle" align="center">
							<h1 className="my-0 text-2xl">Objectives</h1>
							<DatePicker
								picker="year"
								value={dayjs(`${targetYear}-01-01`)}
								onChange={(date) => handleDateChange(date)}
							/>
						</Space>
						<div
							className="my-2 flex"
							style={{ width: '300px', padding: '6px' }}>
							<Tooltip title="Select if you want to view displayed info by Country or Salesperson">
								<p> View info by: </p>
							</Tooltip>
							<Select
								style={{ width: '50%', height: '34px', marginTop: '7px' }}
								className="ml-2"
								defaultValue={[{ value: 'country', label: 'Country' }]}
								onSelect={handleSelect}
								options={items}></Select>
						</div>
					</div>
					<Table
						columns={getTableColumns(quartersQueryResult!.data!.quarters)}
						dataSource={objectiveSummary?.getObjectiveSummary.items}
						size="small"
						bordered
						className="target-table shadow"
						rowKey="rowKey"
						rowClassName={'text-[12px]'}
						pagination={false}
						scroll={{ x: 2400 }}
						summary={() => (
							<TargetTableSummary
								quarters={[
									...quartersQueryResult!.data!.quarters.map((q) => q.quarter),
									'total',
								]}
								totals={objectiveSummary!.getObjectiveSummary.totals}
							/>
						)}
					/>
				</>
			) : (
				<Navigate to="/" />
			)}
		</div>
	);
};

export default TargetPage;
