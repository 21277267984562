import { TvlApiContext } from '@/context/TvlApiContext';
import { UPDATE_PLATFORM_MUTATION } from '@/lib/reportsApi';
import { getFormattedNumber } from '@/lib/utils';
import { useMutation } from '@apollo/client';
import { Button, InputNumber, message, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FC, useContext, useEffect, useState } from 'react';

type Props = {
	spend: number;
	record: any;
	updateField: string;
	successMessage: string;
	refetch: any;
	currency: string;
};

// Formatter y parser definidos fuera del componente
const formatValue = (value: number | string | undefined) =>
	value ? value.toString().replace('.', ',') : '';

const parseValue = (value: string | undefined) =>
	value ? parseFloat(value.replace(',', '.')) : 0;

const CostEditModal: FC<Props> = ({
	spend,
	record,
	updateField,
	successMessage,
	refetch,
	currency,
}: Props) => {
	const [updateReportPlatform, { loading, data, error }] = useMutation(
		UPDATE_PLATFORM_MUTATION
	);

	const { exchangeRates } = useContext(TvlApiContext);
	const [selectedCurrency, setSelectedCurrency] = useState(currency);
	const [cost, setCost] = useState(spend);
	const [exchangedValue, setExchangedValue] = useState(spend);
	const [open, setOpen] = useState(false);

	const handleSave = async () => {
		if (exchangedValue !== undefined && exchangedValue !== cost) {
			await updateReportPlatform({
				variables: { id: record.id, data: { [updateField]: exchangedValue } },
			});
			refetch();
			setOpen(false);
		}
	};

	useEffect(() => {
		if (data) {
			message.success(successMessage);
		} else if (error) {
			message.error(error.message);
		}
	}, [data, error, successMessage]);

	const [form] = useForm();

	useEffect(() => {
		Object.keys(record).forEach((k) => {
			if (record[k] !== form.getFieldValue(k)) {
				form.setFieldValue(k, record[k]);
			}
		});
	}, [record, form]);

	useEffect(() => {
		if (selectedCurrency && cost !== undefined) {
			setExchangedValue(
				(cost *
					exchangeRates.find(
						(r) =>
							r.originCurrency === selectedCurrency &&
							r.destinationCurrency === currency
					)!.rate) as number
			);
		}
	}, [cost, selectedCurrency, currency, exchangeRates]);

	return (
		<>
			<Button type="link" size="small" onClick={() => setOpen(true)}>
				Other currency
			</Button>
			<Modal
				open={open}
				onOk={handleSave}
				onCancel={() => setOpen(false)}
				confirmLoading={loading}
				destroyOnClose
				title="Edit cost in other currency">
				<InputNumber
					className="w-full"
					style={{ height: '32px', marginTop: '19px' }}
					disabled={loading}
					size="middle"
					formatter={formatValue}
					parser={parseValue}
					onChange={(value) => setCost(value || 0)}
					value={cost}
					addonAfter={
						<Select
							className="w-20"
							value={selectedCurrency}
							onChange={setSelectedCurrency}
							options={exchangeRates
								.filter((r) => r.destinationCurrency === currency)
								.map((r) => ({
									value: r.originCurrency,
									label: r.originCurrency,
								}))}
						/>
					}
				/>
				<p className="mt-1 mb-1 text-xs text-gray-500">
					Note: If you're not seeing the desired currency, it's because there is
					not exchange rate for it. Please contact the financial team.
				</p>
				<p>
					The value that will be saved is{' '}
					{getFormattedNumber(exchangedValue, 2)} {currency}
				</p>
			</Modal>
		</>
	);
};

export default CostEditModal;
