import ReactApexChart from 'react-apexcharts';

type Props = {
	data: {
		name: string;
		value: number;
	}[];
};

const CampaignsByMarket = ({ data }: Props) => {
	return (
		<ReactApexChart
			options={{
				legend: {
					show: false,
				},
				chart: {
					height: 300,
					type: 'treemap',
					toolbar: {
						show: false,
					},
				},
			}}
			series={[
				{
					name: 'Campaigns by Market',
					data: data.map((d) => ({ x: d.name, y: d.value })),
				},
			]}
			type="treemap"
			height={300}
		/>
	);
};

export default CampaignsByMarket;
