import { yesNoPending } from '@/lib/constants';
import { capitalize } from '@/lib/utils';
import { Select } from 'antd';
import { FC } from 'react';

type Props = {
  placeholder: string;
  onChange: (value: string) => void;
};

const YesNoPendingSelect: FC<Props> = (props: Props) => {
  return (
    <Select
      {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      {yesNoPending.map((option) => (
        <Select.Option key={option} value={option}>
          {capitalize(option)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default YesNoPendingSelect;
