import { DatePicker, Divider, Form, Input, InputNumber } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { FC } from 'react';
import AccountManagerSelect from './FormFields/AccountManagerSelect';
import AgenciesSelect from './FormFields/AgencySelect';
import BillingCompanySelect from './FormFields/BillingCompanySelect';
import BusinessModelSelect from './FormFields/BusinessModelSelect';
// import CampaignIdField from './FormFields/CampaignIdField';
import CountriesSelect from './FormFields/CountrySelect';
import MarketSelect from './FormFields/MarketSelect';
import ExchangeRateField from './FormFields/ExchangeRateField';
import PlatformsFields from './FormFields/PlatformsFields';
import ProductsSelect from './FormFields/ProductSelect';
import SalespersonSelect from './FormFields/SalespersonSelect';
import ClientSelectField from './FormFields/ClientSelectField';
import PlannerSelect from './FormFields/PlannerSelect';

type Props = {
	form: FormInstance<any>;
	handleFormSubmit: (value: any) => void;
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 19 },
	},
};

const InputDataForm: FC<Props> = ({ form, handleFormSubmit }: Props) => {
	if (form.getFieldValue('exchangeRate') === undefined) {
		// form.setFieldValue('exchangeRate', 'USD');
		form.setFieldValue('exchangeRate', 1);
	}
	const onFinish = (values: any) => {
		handleFormSubmit(values);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo);
	};

	const handleValuesChange = (_: any, allValues: any) => {
		const updatedPlatforms = (allValues.platforms || []).map(
			(platform: any) => {
				if (!platform) return platform;

				const { budget, rate, objectiveType, buyingType } = platform;

				delete platform.objectiveValueBeta;

				let calculatedValue = budget;

				if (budget !== undefined && rate !== undefined && rate !== 0) {
					if (objectiveType === 'absolute' && buyingType === 'CPM') {
						calculatedValue = Math.round((budget / rate) * 1000);
					} else if (objectiveType === 'absolute') {
						calculatedValue = Math.round(budget / rate);
					} else {
						calculatedValue = budget;
					}
				}

				return {
					...platform,
					calculatedValue,
				};
			}
		);

		form.setFieldsValue({ platforms: updatedPlatforms });
	};

	return (
		<Form
			form={form}
			onValuesChange={handleValuesChange}
			labelWrap
			className="w-full"
			id="dataInput"
			{...formItemLayout}
			onFinish={onFinish}
			onFinishFailed={onFinishFailed}
			autoComplete="off">
			<Form.Item
				label="Salesperson"
				name="salesperson"
				rules={[{ required: true, message: 'Please select a Salesperson' }]}>
				<SalespersonSelect
					placeholder="Salesperson"
					onChange={(value) => form.setFieldsValue({ salesperson: value })}
				/>
			</Form.Item>
			<Form.Item
				label="Country"
				name="country"
				rules={[{ required: true, message: 'Please select a country' }]}>
				<CountriesSelect
					placeholder="Country"
					onChange={(value) => form.setFieldsValue({ country: value })}
				/>
			</Form.Item>
			<Form.Item
				label="Market"
				name="market"
				rules={[{ required: true, message: 'Please select a market' }]}>
				<MarketSelect
					placeholder="Market"
					onChange={(value) => form.setFieldsValue({ market: value })}
				/>
			</Form.Item>
			<Form.Item
				label="Agency"
				name="agency"
				rules={[{ required: true, message: 'Please select an agency' }]}>
				<AgenciesSelect
					placeholder="Agency"
					onChange={(value) => form.setFieldsValue({ agency: value })}
				/>
			</Form.Item>
			<Form.Item
				label="Client"
				name="client"
				rules={[{ required: true, message: 'Please input a client' }]}>
				<ClientSelectField
					placeholder="Client"
					onChange={(value) => form.setFieldsValue({ client: value })}
				/>
			</Form.Item>
			<Form.Item
				label="Campaign"
				name="campaign"
				rules={[{ required: true, message: 'Please input a campaign' }]}>
				<Input placeholder="Campaign" />
			</Form.Item>
			<Form.Item label="Product" name="product">
				<ProductsSelect
					placeholder="Product"
					onChange={(value) => form.setFieldsValue({ product: value })}
				/>
			</Form.Item>
			<Form.Item
				label="Time period"
				name="timePeriod"
				rules={[
					{ required: true, message: 'Please select a valid date range' },
				]}>
				<DatePicker.RangePicker className="w-full" />
			</Form.Item>
			<Form.Item label="Order Month" name="billingMonth">
				<DatePicker.RangePicker className="w-full" picker="month" />
			</Form.Item>
			<Form.Item label="Campaign Days" name="campaignDays" initialValue={0}>
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item label="Active Days" name="activeDays" initialValue={0}>
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item
				label="Investment"
				name="investment"
				rules={[{ required: true, message: 'Please input a valid quantity' }]}>
				<InputNumber min={0} style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item label="Billing" name="billing">
				<InputNumber min={0} style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item
				label="Budget"
				name="budget"
				rules={[{ required: true, message: 'Please input a valid quantity' }]}>
				<InputNumber min={0} style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item
				label="Exchange Rate"
				name="exchangeRate"
				tooltip="Exchange rate from currency to USD. If currency is already USD the rate 1."
				rules={[{ required: true, message: 'Please input a valid quantity' }]}>
				<ExchangeRateField
					initialValue={{ currency: 'USD', rate: 1 }}
					onChange={(value) =>
						form.setFieldsValue({ ExchangeRateField: value })
					}
				/>
			</Form.Item>
			<Form.Item label="Benchmark Cost" name="objectiveCost">
				<InputNumber min={0} style={{ width: '100%' }} />
			</Form.Item>
			<Form.Item label="Order number" name="orderNumber">
				<Input />
			</Form.Item>
			<Form.Item label="Bill number" name="billNumber">
				<Input />
			</Form.Item>
			<Form.Item
				label="Account Manager"
				name="accountManager"
				rules={[
					{ required: true, message: 'Please select an Account Manager' },
				]}>
				<AccountManagerSelect
					placeholder="Account Manager"
					onChange={(value) => form.setFieldsValue({ accountManager: value })}
				/>
			</Form.Item>
			<Form.Item label="Planner" name="planner">
				<PlannerSelect
					placeholder="Planner"
					onChange={(value) => form.setFieldsValue({ planner: value })}
				/>
			</Form.Item>
			<Form.Item label="Billing Company" name="billingCompany">
				<BillingCompanySelect
					placeholder="Billing Company"
					onChange={(value) => form.setFieldsValue({ billingCompany: value })}
				/>
			</Form.Item>

			<Form.Item label="Business Model" name="businessModel">
				<BusinessModelSelect
					placeholder="Business Model"
					onChange={(value) => form.setFieldsValue({ businessModel: value })}
				/>
			</Form.Item>

			<Form.Item label="Fee" name="fee">
				<InputNumber min={0} style={{ width: '100%' }} />
			</Form.Item>
			<Divider>Sub Campaign:</Divider>
			<PlatformsFields form={form} />
		</Form>
	);
};

export default InputDataForm;
