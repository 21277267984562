import { gql } from 'graphql.macro';

export interface BasicQueryData {
	name: string;
	id: string;
}

export interface CountriesData {
	countries: BasicQueryData[];
}

const COUNTRIES_QUERY = gql`
	query {
		countries {
			id
			name
		}
	}
`;

export interface SalespeopleData {
	salespeople: BasicQueryData[];
}

const SALESPEOPLE_QUERY = gql`
	query {
		salespeople {
			id
			name
		}
	}
`;

export interface AgenciesData {
	agencies: BasicQueryData[];
}

const AGENCIES_QUERY = gql`
	query {
		agencies {
			id
			name
		}
	}
`;

export interface ClientsData {
	clients: BasicQueryData[];
}

export interface CampaignsData {
	campaigns: BasicQueryData[];
}

const CLIENTS_QUERY = gql`
	query {
		clients {
			id
			name
		}
	}
`;

export interface AllSelectsData {
	agencies: BasicQueryData[];
	salespeople: BasicQueryData[];
	countries: BasicQueryData[];
	accountManagers: BasicQueryData[];
	products: BasicQueryData[];
	clients: BasicQueryData[];
	platforms: BasicQueryData[];
	formats: BasicQueryData[];
	planners: BasicQueryData[];
	billingCompanies: BasicQueryData[];
}

const ALL_SELECTS_QUERY = gql`
	query {
		agencies {
			id
			name
		}
		salespeople {
			id
			name
		}
		countries {
			id
			name
		}
		accountManagers {
			id
			name
		}
		products {
			id
			name
		}
		clients {
			id
			name
		}
		platforms {
			id
			name
		}
		formats {
			id
			name
		}
		planners {
			id
			name
		}
		billingCompanies {
			id
			name
		}
	}
`;

export interface CreateSelectDataMutationVariables {
	salesperson: { name: string }[];
	country: { name: string }[];
	agency: { name: string }[];
	product: { name: string }[];
	client: { name: string }[];
	accountManager: { name: string }[];
}

const CREATE_SELECT_DATA_MUTATION = gql`
	mutation CREATE_SELECT_DATA(
		$salesperson: [SalespersonCreateInput!]!
		$country: [CountryCreateInput!]!
		$agency: [AgencyCreateInput!]!
		$product: [ProductCreateInput!]!
		$client: [ClientCreateInput!]!
		$accountManager: [AccountManagerCreateInput!]!
	) {
		createSalespeople(data: $salesperson) {
			id
		}
		createCountries(data: $country) {
			id
		}
		createAgencies(data: $agency) {
			id
		}
		createProducts(data: $product) {
			id
		}
		createClients(data: $client) {
			id
		}
		createAccountManagers(data: $accountManager) {
			id
		}
	}
`;

export {
	COUNTRIES_QUERY,
	SALESPEOPLE_QUERY,
	AGENCIES_QUERY,
	CLIENTS_QUERY,
	ALL_SELECTS_QUERY,
	CREATE_SELECT_DATA_MUTATION,
};
