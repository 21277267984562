import { CountriesData, COUNTRIES_QUERY } from '@/lib/listsApi';
import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { FC } from 'react';

type Props = {
	placeholder: string;
	onChange?: (value: string) => void;
};

const MarketSelect: FC<Props> = (props: Props) => {
	const { data, loading } = useQuery<CountriesData>(COUNTRIES_QUERY);

	return (
		<Select
			{...props}
			showSearch
			loading={loading}
			optionFilterProp="children"
			mode="multiple"
			filterOption={(input, option) =>
				(option!.children as unknown as string)
					.toLowerCase()
					.includes(input.toLowerCase())
			}>
			{data?.countries.map((country) => (
				<Select.Option key={country.id} value={country.id}>
					{country.name}
				</Select.Option>
			))}
		</Select>
	);
};

export default MarketSelect;
